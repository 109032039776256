.main-simulation-panel {
	position: relative;
	flex: 1 1 auto;
	height: calc(100vh - 80px);
	overflow: hidden;
	@include flexRow(flex-start, flex-start, 0px);
	.simulation-plot-container {
		flex: 1 1 auto;
		height: calc(100vh - 100px);
		position: relative;
		.show-blur {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			backdrop-filter: blur(2px);
			z-index: 99;
		}
		.hide-blur {
			filter: none;
			width: 0;
			height: 0;
		}
		.simulation-plot {
			width: 100%;
			height: 100%;
			@include flexRow(flex-start, center, 0px);
			.plot {
				height: 100%;
				.plot-container {
					height: 100%;
				}
			}
		}
	}
}

.loader {
	width: 100%;
	height: 100%;
	@include flexRow(center, center, 0px);
	&.main-simulation-loader {
		> h3 {
			color: var(--Neutral-1, #23262f);
			font-family: "DM Sans";
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 32px; /* 133.333% */
			text-transform: capitalize;
		}
		> p {
			color: var(--Neutral-2, #718096);
			text-align: center;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px; /* 137.5% */
		}
	}
}

.simulation-point-details-drawer {
	.MuiDrawer-paper {
		border-radius: 20px 20px 0 0;
		width: 100%;
		height: calc(100vh - 50px);
		overflow-y: auto;
		background-color: #fff;
	}
	.simulation-point-header {
		padding: 16px 24px;
		height: 84px;
		border-bottom: 1px solid #f1f3f9;
		@include flexRow(center, space-between, 12px);
		.content {
			@include flexRow(center, flex-start, 12px);
			.spb-back {
				@include flexRow(center, center);
				cursor: pointer;
			}
			.spb-title {
				h3 {
					color: #344054;
					font-family: "DM Sans";
					font-size: 20px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 120% */
				}
				p {
					color: #475467;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}
		.action-buttons {
			@include flexRow(center, flex-end, 12px);
			.download-options,
			.edit-point-btn {
				@include flexRow(center, center, 6px);
				padding: 8px 16px;
				border-radius: 8px;
				border: 1px solid #d0d5dd !important;
				background: #fff !important;
				color: #434f61;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 171.429% */
				> span {
					@include flexRow(center, center, 6px);
				}
			}
		}
	}
	.simulation-point-content {
		@include flexRow(flex-start, flex-start, 0px);
		width: 100%;
		height: calc(100vh - 130px);
		overflow-y: auto;
		.column {
			padding: 16px 24px;
			border-right: 1px solid #f1f3f9 !important;
			flex: 0 0 33.3%;
			height: 100%;
			@include flexCol(flex-start, flex-start, 20px);
			h4 {
				color: #475467;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 133.333% */
				letter-spacing: 0.5px;
				text-transform: uppercase;
			}
			&:last-child {
				border-right: none;
			}

			&.review-stats-column {
				max-width: 33.3%;

				.balance-stats {
					@include flexRow(flex-start, flex-start, 20px);
					flex-wrap: wrap;
					.balance-stats-item {
						flex: 1;
						border-radius: 8px;
						border: 1px solid #e6eaef;
						background: #fff;
						padding: 8px 12px;
						min-width: 120px;
						@include flexCol(flex-start, flex-start, 12px);
						.svg-container {
							width: 28px;
							height: 28px;
							border-radius: var(--radius-sm, 6px);
							background: rgba(17, 17, 17, 0.04);
							@include flexRow(center, center, 0px);
							img {
								width: 20px;
								height: 20px;
							}
						}
						.stats-container {
							h5 {
								color: #718096;
								font-family: "DM Sans";
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: 16px; /* 133.333% */
							}
							h3 {
								color: #000;
								font-feature-settings:
									"ss01" on,
									"cv01" on,
									"cv11" on;
								font-family: "DM Sans";
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 20px; /* 142.857% */
							}
						}
					}
				}

				.list-container {
					width: 100%;
					@include flexRow(flex-start, flex-start, 0px);
					.list {
						width: 50%;
						list-style: none;
						border: 1px solid #e6eaef;
						background: #fff;
						overflow: hidden;
						&:first-child {
							border-radius: 8px 0 0 8px;
						}
						&:last-child {
							border-radius: 0 8px 8px 0;
							border-left: none !important;
						}
						.header {
							background: #fbfcfd;
							@include flexRow(center, flex-start, 12px);
							padding: 16px;
							h3 {
								color: #000;
								font-family: "DM Mono";
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 16px; /* 114.286% */
								letter-spacing: -0.28px;
							}
						}
						.list-item {
							width: 100%;
							padding: 14px 16px;
							@include flexCol(flex-start, flex-start, 8px);
							border-bottom: 1px solid #e6eaef;
							&:last-child {
								border-bottom: none;
							}
							> p {
								color: #475467;
								font-family: "DM Sans";
								font-size: 10px;
								font-style: normal;
								font-weight: 400;
								line-height: 10px; /* 100% */

								&.val {
									color: #182230;
									font-family: "DM Sans";
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 20px; /* 142.857% */
								}
							}
						}
					}
				}
			}

			&.assumptions-key-output-column {
				.assumptions-list {
					width: 100%;
					list-style: none;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 0px;
					border-radius: 8px;
					border: 1px solid #f1f3f9;
					li {
						border-bottom: 1px solid #f1f3f9;
						border-right: 1px solid #f1f3f9;
						position: relative;
						&.even {
							border-right: none;
						}
						padding: 12px 14px;
						@include flexCol(flex-start, flex-start, 2px);
						label {
							color: #475467;
							font-family: "DM Sans";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 12px; /* 100% */
						}
						p {
							color: #000;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
						}
						&.even {
							border-right: none;
						}
						&:last-child {
							border-bottom: none;
						}
						&:nth-last-child(2) {
							&.odd {
								border-bottom: none;
							}
						}

						.edit-icon {
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 16px;
						}
						&.edit {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

.download-menu {
	min-width: 150px;
	max-width: 200px;
	z-index: 9999 !important;
	padding: 0px !important;
	li {
		padding: 16px;
		border-bottom: 1px solid #d0d5dd;
		cursor: pointer;
		color: #000;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 128.571% */
		text-transform: capitalize;
		&:last-child {
			border-bottom: none;
		}
	}
}
