.tape-search-filters {
	width: 100%;
	border-top: 1px solid #f3f5f7;
	padding: 16px 0px;
	.filter-bar {
		@include flexRow(center, flex-end, 16px);
		.all-filters {
			@include flexRow(center, flex-start, 16px);
			.status-menu,
			.upload-date-filter {
				border: 1px solid #dce1ef;
				border-radius: 8px;
				padding: 5px 12px;
				height: 40px;
				width: 200px;
				@include flexCol(flex-start, center);
				.status-select,
				.users-select {
					width: 100%;
					box-shadow: none !important;
					border: none !important;
					outline: none !important;
					height: 30px;
					&:focus {
						border: none !important;
						box-shadow: none !important;
					}
					background-color: transparent !important;
					padding: 0 5px !important;
					min-height: 30px;
					.MuiSelect-button {
						color: #475467;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						text-transform: capitalize;
					}
				}
				.users-select {
					.MuiSelect-button {
						text-transform: capitalize;
					}
				}
				.MuiSelect-select {
					width: 100%;
					padding: 0;
					font-size: 14px;
					font-weight: 400;
					color: var(--dark);
					background-color: #fff;
					border: none;
					border-radius: 0;
					box-shadow: none;
					outline: none !important;
					&:focus {
						border: none;
						box-shadow: none;
					}
				}
			}
			.upload-date-filter {
				position: relative;
				z-index: 1;
				width: 250px;
				.label {
					cursor: pointer;
					width: 100%;
					height: 100%;
					@include flexRow(center, space-between, 16px);
					color: #475467;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					text-transform: capitalize;
				}
				.date-range-picker-container {
					z-index: 50;
					background-color: #fff;
					position: absolute;
					top: 52px;
					left: 0;
					border-radius: 8px;
					border: 1px solid #f2f4f7;
					background: #fff;
					padding: 12px;
					overflow: hidden;
					box-shadow:
						0px 12px 16px -4px rgba(16, 24, 40, 0.12),
						0px 4px 6px -2px rgba(16, 24, 40, 0.04);

					transition: all 0.25s ease-in-out;

					&.show {
						display: block;
						opacity: 1;
						z-index: 1;
					}
					&.hide {
						display: none;
						opacity: 0;
						z-index: -1;
					}

					.close-btn {
						float: right;
						margin: 10px;
						cursor: pointer;
					}

					.reset-btn {
						float: right;
						margin-right: 22px;
						border-radius: var(--radius-md, 8px);
						background: #eaecf0;
						color: #667085;
						font-size: 12px;
						padding: 8px 20px;
						width: max-content;
						cursor: pointer;
						&.reset-active {
							background-color: #fef3f2;
							color: #d92d20;
						}
					}
				}
			}
		}
		.reset-filters {
			padding: 8px 20px;
			height: 40px;
			&.reset-active {
				background-color: #fef3f2 !important;
				color: #d92d20 !important;
			}
			&.reset-disabled {
				background: #eaecf0 !important;
				color: #667085 !important;
			}
		}
	}
}

.rdrDateRangePickerWrapper {
	font-size: 14px;
	.rdrCalendarWrapper {
		box-sizing: border-box !important;
		padding: 10px;
	}
	.rdrMonth {
		width: 280px;
		box-sizing: border-box !important;
		padding: 0 !important;
		margin: auto;

		.rdrWeekDays {
			width: 280px;
			display: grid;
			grid-template-columns: repeat(7, 1fr);
			grid-gap: 0;
			justify-content: flex-start;
			.rdrWeekDay {
				height: 40px;
				width: 40px;
			}
		}
		.rdrDays {
			width: 100%;
			display: grid;
			grid-template-columns: repeat(7, 1fr);
			row-gap: 5px;
			.rdrDay {
				height: 40px;
				width: 40px;
				.rdrInRange,
				.rdrStartEdge,
				.rdrEndEdge,
				.rdrDayInPreview,
				.rdrDayStartPreview,
				.rdrDayEndPreview {
					background: #25aae5 !important;
					height: 100%;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
				.rdrStartEdge,
				.rdrDayStartPreview,
				&.rdrDayStartOfWeek .rdrInRange {
					border-top-left-radius: 50% !important;
					border-bottom-left-radius: 50% !important;
				}
				.rdrEndEdge,
				.rdrDayEndPreview,
				&.rdrDayEndOfWeek .rdrInRange {
					border-top-right-radius: 50% !important;
					border-bottom-right-radius: 50% !important;
				}
				.rdrDayInPreview,
				.rdrDayEndPreview,
				.rdrDayStartPreview {
					background: transparent !important;
				}
				.rdrDayNumber {
					height: 40px;
					width: 40px;
					position: relative;
					top: 0;
					left: 0;
					bottom: 0;
					@include flexRow(center, center);
					.rdrInRange {
						position: absolute;
						top: 0;
						left: 0;
					}
					span {
						height: 40px;
						width: 40px;
						font-size: 14px;
					}
				}
			}
		}
	}
}
