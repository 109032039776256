.hammer-drawer {
	width: 100%;
	height: 100%;
	border-radius: 20px 20px 0px 0px;
	background: #fff;
	position: relative;
	@include flexCol(flex-start, flex-start, 0px);
	.head-row {
		width: 100%;
		padding: 16px 24px;
		border-bottom: 1px solid #f1f3f9;

		@include flexRow(center, space-between, 0px);
		.left {
			@include flexRow(center, flex-start, 16px);
			width: 50%;
		}
	}
	.main-container {
		width: 100%;
		height: calc(100vh - 200px);
		display: grid;
		grid-template-columns: 2fr 1fr;
		overflow: auto;
		.parameters-container {
			width: 100%;
			border-right: 1px solid #f1f3f9;
			.collection {
				width: 100%;
				@include flexCol(flex-start, flex-start, 0px);
				.row {
					width: 100%;
					@include flexRow(stretch, flex-start, 0px);
					border-bottom: 1px solid #f1f3f9;
					&.title-row {
						background: #f8f9fc;
						padding: 16px 20px;
						.title-row-name {
							@include flexRow(center, flex-start, 0px);
							color: #0c111d;
							font-family: "DM Mono";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							letter-spacing: -0.28px;
						}
						.edit {
							width: 100%;
							@include flexRow(center, flex-end, 10px);
							.icon {
								@include flexRow(center, center);
								cursor: pointer;
								svg {
									color: rgb(113, 128, 150);
									width: 24px;
									height: 24px;
								}
							}
						}
					}

					.name,
					.index {
						padding: 16px 20px;
						border-right: 1px solid #f1f3f9;
						height: inherit;
						@include flexRow(center, flex-start, 0px);
						color: #000;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
						width: calc((100% - 80px) / 2);
					}
					.index {
						width: 80px;
						@include flexRow(center, center, 0px);
					}

					.data {
						width: calc((100% - 80px) / 2);
						display: grid;
						grid-template-columns: 1fr 1fr 1fr;

						.data-row {
							border-right: 1px solid #f1f3f9;
							padding: 16px 20px;
							@include flexCol(flex-start, flex-start, 3px);
							&:last-child {
								border-right: none;
							}
							.label {
								color: #475467;
								font-family: "DM Mono";
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: 16px; /* 133.333% */
								letter-spacing: -0.96px;
								text-transform: lowercase;
							}
							.value {
								.text-input-field {
									width: 100%;
									height: 30px;
									border: none;
									border-radius: 0px;
									background: #fff;
									box-shadow: none;
									padding: 0px;
									border-bottom: 1px solid #f1f3f9;
									&::before {
										border: 1px solid
											var(--Input-focusedHighlight);
										transform: scaleX(0);
										left: 0;
										right: 0;
										bottom: -2px;
										top: unset;
										transition: transform 0.15s
											cubic-bezier(0.1, 0.9, 0.2, 1);
										border-radius: 0;
									}
									&:focus-within::before {
										transform: scaleX(1);
									}

									.MuiInput-input {
										width: 100%;
										color: #182230;
										font-family: "DM Sans";
										font-size: 14px;
										font-style: normal;
										font-weight: 400;
										line-height: 20px; /* 142.857% */
									}
								}
								color: #182230;
								font-family: "DM Sans";
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px; /* 142.857% */
							}
						}
						&.hammer-details {
							grid-template-columns: 1fr 1fr;
							.data-row {
								@include flexRow(center, flex-start, 10px);
								svg {
									color: #d6d6d6;
								}
							}
						}
					}
				}
			}
		}
	}
	.footer {
		position: fixed;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 70px;
		border-top: 1px solid #f2f4f7;
		background: #fff;
		padding: 10px 24px;
		@include flexRow(center, flex-end, 0px);
		&.btn-type-primary {
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			min-height: 40px;
			padding: 8px 16px !important;
			border-radius: 8px !important;
			border: 1px solid #25aae5;
			background-color: #25aae5;
			&.disabled {
				.MuiButton-startDecorator {
					svg {
						width: 20px;
						height: 20px;
						path {
							stroke: #9499a4 !important;
						}
					}
				}
			}
			.MuiButton-startDecorator {
				svg {
					width: 20px;
					height: 20px;
					path {
						stroke: #fff;
					}
				}
			}
		}
	}
}
.hammer-menu {
	min-width: 150px;
	max-width: 330px;
	.selected-value-container {
		width: 100%;
		border-radius: 8px;
		border: 1px solid #f1f3f9;
		background: #fff;
		padding: 6px 12px;
		min-height: 55px;
		@include flexRow(center, space-between, 0);
		&.disabled-container {
			cursor: not-allowed;
			border: 1px solid #f1f3f9;
			background: #fafbff;
		}
		.text {
			@include flexCol(flex-start, flex-start, 5px);
			> p {
				color: #475467;
				font-family: "DM Mono", monospace;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				letter-spacing: -0.96px;
				text-transform: capitalize;
			}
			> h4 {
				color: #182230;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				text-align: left;
			}
		}
		.icon {
			@include flexRow(center, center, 0);
			svg {
				width: 16px;
				height: 16px;
				color: #718096;
			}
			transition: all 0.1s ease-in-out;
			&.rotate90d {
				transform: rotate(90deg);
			}
			&.rotate0d {
				transform: rotate(0deg);
			}
		}
	}
}

.hammer-menu-list {
	width: 100% !important;
	max-width: 330px;
	min-width: 150px;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	padding-top: 0;
	z-index: 1500 !important;
	left: 80px !important;
	> li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		padding: 12px 16px;
		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}
		.menu-item {
			width: 100%;
			@include flexRow(center, flex-start, 8px);
			> h4 {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.28px;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}
