.column-mapping {
	margin: auto;
	width: calc(100% - 128px);
	height: 100%;
	.column-mapping-header {
		padding: 16px 20px;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		border: 0.5px solid #dce1ef;
		flex-wrap: wrap;
		@include flexRow(center, space-between, 16px);
		.column-mapping-progress,
		.actions {
			@include flexRow(center, flex-start, 16px);
		}

		.column-mapping-progress {
			@include flexCol(flex-start, flex-start, 8px);
			h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px; /* 140% */
			}
			.progress-bar {
				width: 200px;
				padding: 5px;
				&::before {
					height: 10px;
					background-color: #f8c46d;
				}
				&.completed::before {
					background-color: #17b26a;
				}
			}
		}

		.actions {
			position: relative;
		}
	}
	.column-mapping-container {
		width: 100%;
		@include flexCol(flex-start, flex-start, 0px);
		height: calc(100vh - 144px - 64px - 16px);
		overflow-y: auto;

		.column-mapping-container-header {
			width: 100%;
			border-bottom: 1px solid #f9fafb;
			background: #f8f9fc;
			display: grid;
			grid-template-columns:
				0.2fr 1.5fr 0.3fr minmax(0, 3fr)
				0.8fr;
			> div {
				&:first-child {
					width: 58px;
				}
				padding: 16px 20px;
				h4 {
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
				}
				p {
					color: #718096;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.28px;
				}
			}
		}

		.column-mapping-item {
			width: 100%;
			display: grid;
			grid-template-columns:
				0.2fr 1.5fr 0.3fr minmax(0, 3fr)
				0.8fr;
			border: 1px solid #f9fafb;

			h4 {
				width: 100%;
				font-family: "DM Mono", monospace;
				color: #000;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
			}
			&.even {
				border-bottom: 1px solid #f9fafb;
				background: #fcfcfd;
			}
			&.odd {
				border-bottom: 1px solid #f9fafb;
				background: #fff;
			}

			.sn {
				padding: 16px;
				width: 58px;
			}

			.column-mapping-key {
				padding: 16px 20px;
				h4 {
					width: 100%;
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
				}
				p {
					color: #718096;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.28px;
				}
			}

			.arrow {
				@include flexRow(center, center);
				svg {
					width: 32px;
					height: 32px;
					flex-shrink: 0;
					color: #dce1ef;
				}
			}

			.column-mapping-value {
				padding: 16px 20px;
				grid-auto-flow: dense;
				&.invalid {
					background: #fef3f2;
				}
				.column-mapping-value-content {
					@include flexCol(flex-start, flex-start, 0px);
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
					.column-mapping-code-editor {
						width: 100%;
						height: 100%;
						overflow: auto;
					}

					.empty-text {
						color: #b42318;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: -0.28px;
					}
				}
			}

			.actions {
				@include flexRow(center, center, 8px);
				svg {
					position: relative;
					width: 28px;
					height: 28px;
					color: #718096;
					cursor: pointer;
				}
				.checked {
					color: #9bc171;
				}
				span {
					@include flexRow(center, center, 0px);
					&.disabled-approve-btn {
						opacity: 0.5;
						svg {
							cursor: unset;
						}
					}
					&::before {
						content: "";
						width: 1px;
						height: 12px;
						background: #edf0f3;
						margin-right: 10px;
						opacity: 0.75;
					}
				}
			}
		}
	}
}

.toggler {
	cursor: pointer;
	width: 64px;
	height: 36px;
	border-radius: 18px;
	border: 1px solid #edeff2;
	background: #f8f9fc;
	position: relative;
	span {
		position: absolute;
		background-color: #98a2b3;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		left: 2px;
		top: 1.2px;
		transition: all 0.3s ease;
		@include flexRow(center, center);
		color: #fff;
	}

	&.code {
		background-color: #17b26a;
		span {
			left: 28px;
			background-color: #fff;
			color: #17b26a;
		}
	}
}
