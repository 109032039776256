.tape-search-page {
	padding: 15px 50px;
	width: 100%;
	.tape-search-header {
		width: 100%;
		@include flexRow(center, space-between);
		> h3 {
			color: #101828;
			font-family: "DM Sans";
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 40px; /* 200% */
			letter-spacing: -0.4px;
			text-transform: capitalize;
			> span {
				color: #718096;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 40px;
				letter-spacing: -0.32px;
				text-transform: lowercase;
			}
		}
		.tape-search-filter {
			width: 60%;
			@include flexRow(center, flex-start, 15px);
			.search-field {
				width: 100%;
				@include flexRow(center, space-between);
				overflow: hidden;
				height: 45px;

				.end-decorator {
					width: auto;
					height: 100%;
					margin-right: 10px;
					> button {
						height: 100%;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-top-right-radius: 8px;
						border-bottom-right-radius: 8px;
					}
				}
				&:focus-within {
					.end-decorator {
						> button {
							border-radius: 0;
							// border-top-left-radius: 0;
							// border-bottom-left-radius: 0;
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
						}
					}
				}
			}
			.filter-btn {
				svg {
					font-size: 16px;
					color: #718096;
				}
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				border-radius: var(--radius-md, 8px);
				border: 1px solid #d0d5dd;
				background: #fff !important;
				height: 45px;
			}
			.upload-btn {
				height: 45px;
				border: none;
				svg {
					font-size: 20px;
				}
			}
		}
	}
	.filters {
		width: 100%;
		@include flexRow(center, flex-start);
		margin-top: 10px;
		// overflow: hidden;
		transition: all 0.25s ease-in-out;
		margin-bottom: 20px;
		position: relative;
		&.show {
			height: 65px;
			opacity: 1;
			z-index: 1;
		}
		&.hide {
			height: 0px;
			opacity: 0;
			z-index: -1;
		}
		.search-filter {
			border: 1.5px solid #f0f2f8;
			border-radius: 8px;
			gap: 2px;
			padding: 5px;
			max-width: 200px;
			height: 60px;
			label {
				font-size: 12px;
			}
			.input-container {
				height: 30px;
				border: none !important;
				border-radius: 0;
				&:focus-within {
					border: 2px solid var(--primary);
					box-shadow: none !important;
				}
				> input {
					border: none !important;
					border-radius: 0;
					box-shadow: none !important;
					&:focus {
						border: none !important;
						box-shadow: none !important;
					}
				}
			}
		}
	}
	.tape-search-container {
		width: 100%;
		position: relative;
		.table-header {
			border-bottom: 1px solid #f9fafb;
			background: #f9fafb;
			.table-row {
				overflow: hidden;
				display: grid;
				grid-template-columns: 2fr 0.8fr 1fr 1fr 1fr 0.5fr;
				&.no-uploaded-by {
					grid-template-columns: 2fr 0.8fr 1fr 1fr 0.5fr;
				}
				> p {
					color: #475467;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 18px; /* 150% */
					text-transform: capitalize;
					text-align: left;
					padding: 10px;
					&:last-child {
						display: flex;
						justify-content: center;
						align-items: center;
					}
					&.asset-class,
					&.date-uploaded {
						@include flexRow(flex-start, flex-start);
					}
				}
				&.lithe-row {
					grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
				}
			}
		}
		.search-results {
			width: 100%;
			min-height: 600px;
			@include flexCol(center, flex-start, 0px);
			.search-loader {
				margin: 100px auto;
				width: 50px;
			}
			.table-row {
				width: 100%;
				overflow: hidden;
				display: grid;
				grid-template-columns: 2fr 0.8fr 1fr 1fr 1fr 0.5fr;
				border-bottom: 1px solid #f9fafb;
				&.no-uploaded-by {
					grid-template-columns: 2fr 0.8fr 1fr 1fr 0.5fr;
				}
				&:hover {
					background-color: rgb(152, 162, 179, 0.06);
				}

				.search-item {
					cursor: pointer;
					width: 100%;
					overflow: hidden;
					color: #000;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					text-align: left;
					padding: 10px;
					@include flexRow(center, flex-start);
					min-height: 57px;
					&:last-child {
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						width: 50px;
						height: 50px;
						min-height: 50px;
						border-radius: 50%;
						margin: auto;
						.select-tape-icon {
							color: #98a2b3;
							font-size: 20px;
						}

						@include flexRow(center, center);
						&:hover {
							background-color: rgb(152, 162, 179, 0.075);
							.select-tape-icon {
								color: #3ca662;
							}
						}
					}
					&.asset-class,
					&.date-uploaded {
						@include flexRow(center, flex-start);
					}
					&.uploaded-by {
						.uploaded-by-icon {
							width: 35px;
							height: 35px;
							border-radius: 50%;
							overflow: hidden;
							background-color: #7f56d9;
							color: #fff;
							@include flexRow(center, center);
							font-weight: 500;
						}
					}
					&.status > p {
						padding: 3px 10px;
						border-radius: 6px;
						background: rgba(131, 178, 79, 0.1);
						color: #729c44;
						text-align: center;
						font-style: normal;
					}
				}
				&.lithe-row {
					grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
				}
			}
			.no-tapes-found {
				width: 100%;
				text-align: center;
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				margin-top: 100px;
			}
		}
		.pagination {
			margin-top: 10px;
			@include flexCol(center, center);
			> p {
				color: #475467;
				text-overflow: ellipsis;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 18px; /* 150% */
				text-transform: lowercase;
			}
		}
	}
}

.select-tape-item {
	width: 150px;
	@include flexRow(center, space-between, 8px);
	.label {
		flex: 1 1 calc(100% - 20px);
		color: #475467;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
	> svg {
		width: 20px;
		height: 20px;
	}
}
