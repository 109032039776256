.data-range {
	margin: auto;
	width: calc(100vw - 128px);
	height: 100%;
	.data-actions {
		padding: 16px 20px;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		border: 0.5px solid #dce1ef;
		flex-wrap: wrap;
		@include flexRow(center, space-between, 16px);
		.data-selection-bar,
		.actions {
			@include flexRow(center, flex-start, 16px);
		}

		> .actions {
			position: relative;
			button {
				border-radius: 8px !important;
				border: 1px solid #d0d5dd !important;
				background: #fff !important;
				.MuiButton-startDecorator {
					color: #718096;
					svg {
						width: 20px;
						height: auto;
					}
				}
				color: #475467;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				&.reload-tape-btn {
					svg {
						animation: rotate 1.5s linear 3;
					}
					&.disabled {
						pointer-events: none;
						opacity: 0.5;
						svg {
							animation: unset;
						}
					}
				}
			}
		}

		.data-selection-bar {
			.sheet-input,
			.range-input {
				position: relative;
				@include flexRow(center, flex-start, 16px);
				.range-input-field,
				.sheet-input-field {
					border-radius: var(--radius-md, 8px);
					border: 1px solid #dce1ef;
					box-shadow: none !important;
					width: 180px;
					background-color: #fff !important;
					.MuiInput-startDecorator {
						background-color: #fff !important;
					}
					label {
						color: #98a2b3;
						font-size: 14px;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						text-transform: lowercase;
					}
					> input,
					> button {
						color: #344054;
						font-size: 14px;
						font-weight: 600;
						line-height: 20px;
						background-color: #fff !important;
					}
				}
				.sheet-input-field {
					width: 200px !important;
				}
				.range-input-field {
					.MuiInput-input {
						text-transform: uppercase !important;
					}
				}
			}
		}
	}
	.data-preview {
		height: calc(100vh - 144px - 64px - 16px);
		overflow: auto;
		border-left: 0.5px solid #dce1ef;
		border-right: 0.5px solid #dce1ef;
		border-bottom: 0.5px solid #dce1ef;

		/* Scrollbar Styles */
		&::-webkit-scrollbar {
			width: 6px;
			height: 8px;
		}

		&::-webkit-scrollbar-track {
			background: rgba(208, 213, 221, 0.2);
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(208, 213, 221, 0.5);
			border-radius: 8px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: rgba(208, 213, 221, 0.8);
		}

		table {
			width: 100%;
			height: 100%;
			border-spacing: 0 !important;
			// border: 0.5px solid #dce1ef;

			thead {
				tr {
					background: #f9fafb;
					th {
						color: #6c7c93;
						font-family: "DM Mono";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 166.667% */
						width: max-content;
						min-width: 150px;
						padding-left: 5px;
						padding-right: 5px;
					}
				}
			}
			tbody {
				tr {
					td {
						border-left: 0.5px solid #dce1ef;
						border-top: 0.5px solid #dce1ef;
						padding: 4px 8px;
						> pre {
							color: #000;
							font-family: "DM Mono";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							letter-spacing: 0.25px;
						}
						&:first-child {
							border-left: none;
						}
						.react-loading-skeleton {
							background: linear-gradient(
								90deg,
								rgba(220, 225, 239, 0.25) 0%,
								rgba(126, 129, 137, 0.2) 90%
							);
							opacity: 0.75;
						}
					}
				}
			}
		}
		.invalid-range-selection {
			width: 100%;
			height: 100%;
			margin: auto;
			@include flexCol(center, center, 10px);
			h4 {
				color: #f24e1e;
				font-family: "DM Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 1px;
			}
			p {
				color: #6c7c93;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 166.667% */
			}
		}
	}
}

.preview-button {
	border-radius: 8px !important;
	border: 1px solid #d0d5dd !important;
	background: #fff !important;
	.MuiButton-startDecorator {
		color: #718096 !important;
		svg {
			width: 20px;
			height: auto;
		}
	}
	color: #475467 !important;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.data-range-coach-mark,
.toggler-coach-mark,
.reload-coach-mark {
	max-width: 200px;
	@include flexCol(flex-start, flex-start, 8px);
	h4 {
		color: fff;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	p {
		color: rgba(255, 255, 255, 0.5);
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.outline-btn {
		align-self: flex-end;
		background-color: transparent !important;
		color: #25aae5 !important;
		text-align: right;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 0 !important;
		height: auto !important;
		cursor: pointer;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
