.remove-file-modal {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	@include flexRow(center, center, 10px);
	&:focus {
		outline: none !important;
	}
	.remove-file-box {
		border-radius: var(--radius-xl, 12px);
		background: var(--Colors-Background-bg-primary, #fff);

		/* Shadows/shadow-xl */
		box-shadow:
			0px 8px 8px -4px rgba(16, 24, 40, 0.03),
			0px 20px 24px -4px rgba(16, 24, 40, 0.08);

		width: 400px;
		padding: 20px;
		.remove-file-header {
			@include flexRow(flex-start, space-between, 10px);
			.trash {
				display: flex;
				width: 48px;
				height: 48px;
				padding: 9px;
				justify-content: center;
				align-items: center;
				border-radius: var(--radius-lg, 10px);
				border: 1px solid #eaecf0;
			}
			span svg {
				width: 30px;
				height: 30px;
			}
			.close {
				color: #98a2b3;
			}
		}
		.remove-file-content {
			margin: 16px 0 32px;
			h3 {
				color: #23262f;
				font-family: "DM Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 28px; /* 155.556% */
				margin-bottom: 8px;
			}
			p {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
		.btns-containers {
			@include flexRow(center, space-between, 10px);
			button {
				flex: 1;
				padding: 10px 12px;
				font-size: 16px;
				font-weight: 600;
				line-height: 150%;
			}
			.cancel-btn {
				color: #344054;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px; /* 150% */
				border-radius: 8px;
				border: 1px solid #d0d5dd;
				background: #fff !important;
			}
			.remove-btn {
				border-radius: var(--radius-md, 8px);
				border: 1px solid #d92d20 !important;
				background: #d92d20 !important;
				color: #fff;
				&:hover {
					background: #d92d20;
				}
			}
		}
	}
}
