.simulation-filter {
	width: 100%;
	height: calc(100vh - 50px);
	background-color: #fff;
	border-radius: 15px 15px 0 0;
	padding: 16px 64px;
	overflow-y: auto;
	@include flexCol(flex-start, flex-start, 10px);
	.simulation-filter-header {
		width: 100%;
		// @include flexRow(center, flex-start, 2px);
		@include flexCol(flex-start, flex-start, 2px);
		padding: 14px 8px;
		h3 {
			color: #344054;
			font-family: "DM Sans";
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 120% */
		}
		p {
			color: #98a2b3;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}

	.filter-controller-container {
		width: 100%;
		padding: 10px 0 70px 0;
		@include flexRow(flex-start, flex-start, 16px);
		.column-1 {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 16px;
			.filter-controller {
				width: 100%;
				border-radius: 8px;
				border: 1px solid #f1f3f9;
				background: #fff;
				padding: 20px;
				height: fit-content;
				min-height: 145px;
				@include flexCol(flex-start, flex-start, 18px);
				.filter-head {
					width: 100%;
					@include flexRow(center, space-between, 0px);
					h4 {
						color: #475467;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 16px; /* 133.333% */
						letter-spacing: 0.5px;
						text-transform: uppercase;
					}
					.filter-type-select {
						border-radius: 8px;
						border: 1px solid #eaedf5;
						background: #fff;
						box-shadow: none !important;
					}
				}
				.controller {
					width: 100%;
					.array-controller,
					.range-controller .range-input {
						.text-input {
							border-radius: 8px;
							border: 1px solid #eaedf5;
							background-color: #fff !important;
							padding: 12px 14px;
							text-overflow: ellipsis;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							box-shadow: none !important;
						}
					}
					.range-controller {
						.range-slider {
							&.MuiSlider-root {
								color: #475467;
								.MuiSlider-rail {
									background-color: #eaedf5 !important;
								}
								.MuiSlider-track {
									background-color: #25aae5;
									border-color: #25aae5;
								}
								.MuiSlider-thumb {
									background-color: #25aae5;
									border: 2px solid #fff;
									box-shadow: 0px 2px 15px
										rgba(0, 0, 0, 0.1);
								}
							}
						}
						.range-input {
							width: 100%;
							@include flexRow(center, space-between, 8px);
							.text-input {
								flex: 1 1 50%;
								width: 100%;
							}
						}
					}
					.array-controller {
						position: relative;
						.tag-list {
							z-index: 10;
							width: 100%;
							position: absolute;
							top: calc(100% + 16px);
							left: 0;
							max-height: 250px;
							overflow-y: auto;
							overflow-x: hidden;
							@include flexCol(flex-start, flex-start, 8px);
							list-style: none;
							border-radius: 8px;
							border: 1px solid #f2f4f7;
							background: #fff;

							/* Dropdown */
							box-shadow:
								0px 12px 16px -4px rgba(16, 24, 40, 0.08),
								0px 4px 6px -2px rgba(16, 24, 40, 0.03);

							&.hide {
								display: none;
							}
							&.show {
								display: block;
							}

							> li {
								color: #344054;
								font-family: "DM Sans";
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px; /* 142.857% */
								padding: 16px 20px;
								width: 100%;
								@include flexCol(
									flex-start,
									flex-start,
									2px
								);
								&:hover,
								&.selected {
									background: #f8f9fc;
									cursor: pointer;
								}
								> span {
									color: #475467;
									font-family: "DM Sans";
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 16px; /* 133.333% */
								}
							}
						}
					}
					.all-applied-tags {
						width: 100%;
						margin-top: 16px;
						@include flexRow(center, flex-start, 8px);
						.applied-tag {
							border-radius: 6px;
							background: #f8f9fc;
							padding: 6px 8px;
							@include flexRow(center, flex-start, 8px);
							svg {
								color: #acb5c3;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}

	.bottom-controller {
		position: fixed;
		bottom: 0;
		width: calc(100vw - 64px - 64px);
		background-color: #fff;
		border-top: 1px solid #f9fafb;
		padding: 16px 0px;
		@include flexRow(center, space-between, 0px);
		.close-tape {
			border-radius: 8px;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.filter-btn-group {
			@include flexRow(center, center, 16px);
			.apply-filter {
				@include flexRow(center, center, 8px);
				&.disabled-btn {
					pointer-events: none;
					opacity: 0.5;
					color: #fff;
				}
			}
		}
	}
}

.MuiSelect-listbox {
	z-index: 9999 !important;
}
