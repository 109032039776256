.hammer-screen {
	width: 100%;
	height: 100%;
	@include flexCol(flex-start, flex-start, 0);
	.hammer-top-bar {
		width: 100%;
		height: 72px;
		padding: 16px 24px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		border-bottom: 1px solid #ebf1fa;
		.hammer-top-bar-left {
			@include flexRow(center, flex-start, 12px);
			h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 40px; /* 200% */
				letter-spacing: -0.4px;
			}
		}
		.hammer-top-bar-mid {
			@include flexRow(center, center, 0);
			.tab {
				@include flexRow(center, center, 12px);
				cursor: pointer;
				padding: 6px 16px;
				border: 1px solid #d0d5dd;
				height: 40px;
				> p {
					color: #718096;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
				&.active {
					> p {
						color: #475467;
					}
				}
				&:first-child {
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;
					border-right: none;
				}
				&:last-child {
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
				}
			}
		}

		.hammer-top-bar-right {
			@include flexRow(center, flex-end, 12px);
			.alloan-button {
				padding: 10px 12px;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.56px;
			}
			.save-hammer-btn {
				width: 150px !important;
			}
			.download-cashflow-btn {
				width: 200px !important;
			}
		}
	}
	.hammer-container {
		width: 100%;
		height: calc(100% - 72px);
		@include flexRow(flex-start, flex-start, 0);
		.left-container {
			width: 50%;
			height: calc(100% - 65px);
			border-right: 1px solid #ebf1fa;
			position: relative;
			overflow-y: auto;
			.tranche,
			.hammer-global-assumption,
			.select-hammer {
				width: 100%;
				display: grid;
				grid-template-columns: 50px 1fr 2fr 50px;
				border-bottom: 1px solid #f1f3f9;
				gap: 0;
				.sno {
					width: 50px;
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
					@include flexRow(center, center, 0);
					border-right: 1px solid #f1f3f9;
				}
				.tranche-name,
				.title {
					min-width: 200px;
					padding: 16px 20px;
					@include flexRow(center, flex-start, 0);
					border-right: 1px solid #f1f3f9;
				}
				.tranche-data {
					@include flexCol(flex-start, flex-start, 0);
					border-right: 1px solid #f1f3f9;
				}
				.cashflow {
					width: 100%;
					border-bottom: 1px solid #f1f3f9;
				}
				.parameters {
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					.coupon,
					.advance,
					.shock,
					.question {
						padding: 8px 12px;
						span {
							color: #475467;
							font-family: "DM Mono";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px; /* 133.333% */
							letter-spacing: -0.96px;
							text-transform: lowercase;
						}
						p {
							color: #182230;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
						}
						border-left: 1px solid #f1f3f9;
						&:first-child {
							border-left: none;
						}
						&:nth-child(4) {
							border-left: none;
							border-right: 1px solid #f1f3f9;
						}
					}
				}
				.tranche-delete {
					@include flexRow(center, center, 0);
					padding: 16px;
					cursor: pointer;
					&.disabled {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}
			}
			.residual-tranche {
				.tranche-data {
					border-right: none;
					.cashflow {
						width: calc(100% + 50px);
						padding-right: 50px;
						border-bottom: none;
					}
				}
			}
			.select-hammer .parameters {
				grid-template-columns: 1fr 1fr;
				width: 100%;
				.option {
					@include flexRow(center, flex-start, 15px);
					padding: 16px;
					border-left: 1px solid #f1f3f9;
					.radio {
						width: 16px;
						height: 16px;
						color: #25aae5;
					}
					p {
						color: #434f61;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
					}
					&:first-child {
						border-left: none;
					}
				}
			}

			.add-tranche {
				padding: 16px;
				width: 100%;
				@include flexRow(center, flex-end, 20px);
				border-bottom: 1px solid #f1f3f9;
				.alloan-button {
					width: 200px;
				}
			}
		}
		.right-container {
			width: 50%;
			height: calc(100% - 65px);
			overflow-y: auto;
			padding: 16px 24px;
			h3 {
				color: #000;
				font-family: "DM Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px; /* 122.222% */
			}
			.analysis-results-list {
				margin-top: 16px;
				list-style: none;
				border-top: 1px solid #d0d5dd;
				border-left: 1px solid #d0d5dd;
				margin-bottom: 50px;
				.analysis-results-list-item {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					list-style: none;
					border-bottom: 1px solid #d0d5dd;
					&.header-item {
						background: #f9fafb;
						li {
							color: #6c7c93;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 166.667% */
							letter-spacing: -0.48px;
						}
					}
					li {
						padding: 16px;
						color: #101828;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						border-right: 1px solid #d0d5dd;
					}
				}
			}
		}
		.hammer-footer {
			position: fixed;
			bottom: 0;
			width: 100%;
			height: 65px;
			padding: 10px 24px;
			@include flexRow(center, space-between, 0);
			border-top: 1px solid #f2f4f7;
			background-color: #fff;

			.alloan-button {
				width: 150px;
			}

			.btn-group {
				@include flexRow(center, flex-end, 16px);
				.alloan-button {
					width: 150px;
				}
			}
		}
	}
}
