.extract-lithe-data {
	width: 100%;
	height: 100%;
	.questions-container {
		width: 100%;
		.question-data {
			@include flexRow(center, flex-start, 0px);
			border-bottom: 1px solid #f1f3f9;
			background: #fff;
			color: #0c111d;
			font-family: "DM Mono";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			letter-spacing: -0.28px;
			height: 65px;
			&.header {
				background: #f8f9fc;
				.question,
				.answer {
					h4 {
						color: #000;
						font-family: "DM Mono";
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
					}
				}
			}
			.sno {
				width: 80px;
				padding: 16px;
				border-right: 1px solid #f1f3f9;
				@include flexRow(center, center, 0px);
				height: inherit;
			}
			.question,
			.answer {
				width: calc(((100% - 80px) / 2));
				padding: 16px 20px;
				border-right: 1px solid #f1f3f9;
				height: inherit;
				@include flexRow(center, flex-start, 0px);
				.answer-dropdown {
					width: calc(100% - 40px);
					background-color: transparent;
					box-shadow: none;
					border: none;
					padding-inline: 0px;
				}
				.inline-input-editor {
					width: calc(100% - 40px);
				}
				.skeleton {
					width: 100% !important;
				}
			}
		}
	}
}

.MuiSelect-listbox {
	padding: 0 !important;
}

.global-options-list-item {
	color: #475467 !important;
	font-family: "DM Sans" !important;
	font-size: 16px !important;
	border-bottom: 1px solid #f1f3f9 !important;
	background: #fff;
	text-transform: capitalize;
	padding: 14px 24px !important;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	&:last-child {
		border-bottom: none !important;
	}
	&.Mui-selected {
		background-color: transparent !important;
		color: var(--Primary, #25aae5) !important;
		font-family: "DM Sans";
	}
}
