.edit-assumption {
	width: 100%;
	height: calc(100vh - 50px);
	overflow-y: auto;
	background-color: #fff;
	border-radius: 15px 15px 0 0;
	padding: 16px 64px;
	@include flexCol(flex-start, flex-start, 20px);
	.edit-assumption-header {
		width: 100%;
		@include flexRow(center, space-between);
		.title {
			@include flexCol(flex-start, flex-start, 2px);
			h3 {
				color: #344054;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #98a2b3;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
		.run-analysis {
			@include flexRow(center, center, 8px);
			&.disabled-btn {
				pointer-events: none;
				opacity: 0.5;
				color: #fff;
			}
		}
	}
	.question-tab-container {
		@include flexRow(center, flex-start, 12px);
		.tab {
			border: 1px solid #d0d5dd;
			border-radius: 8px;
			background: #fff;
			padding: 8px 16px;
			color: #434f61;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			cursor: pointer;
			@include flexRow(center, center, 8px);
			&.active {
				color: #000;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px; /* 142.857% */
			}
		}
	}
	.edit-assumption-content {
		width: 100%;
		border: 1px solid #e6eaef;
		border-bottom: none;
		margin-bottom: 80px;
		border-radius: 16px 16px 0 0;
		.content-row-header {
			border-bottom: 1px solid #e6eaef;
			background: #fbfcfd;
			display: grid;
			grid-template-columns: 0.3fr 2.5fr 1fr;
			border-radius: 16px 16px 0 0;
			.sno {
				padding: 16px;
			}
			.question,
			.answer {
				padding: 16px 20px;
				h4 {
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
				}
				p {
					color: #718096;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.28px;
				}
			}
		}
		.content-row-element {
			border-bottom: 1px solid #e6eaef;
			display: grid;
			grid-template-columns: 0.3fr 2.5fr 1fr;
			.sno {
				padding: 16px;
				border-right: 1px solid #e6eaef;
				color: #000;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
			}
			.question {
				padding: 16px 20px;
				color: #0c111d;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
				border-right: 1px solid #e6eaef;
			}
			.answer {
				padding: 16px 20px;
				cursor: pointer;
				position: relative;
				&.option-edit {
					@include flexRow(center, flex-start, 8px);
					.select-option {
						flex: 1;
					}
					.delete {
						width: 24px;
						height: 24px;
						@include flexRow(center, center, 0px);
					}
				}
				.value-container {
					width: 100%;
					@include flexRow(center, space-between, 6px);
					> span {
						width: calc(100% - 30px);
						color: #718096;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 270px;
					}
					> svg {
						width: 24px;
						height: 24px;
						font-size: 24px;
					}
				}
			}
		}
	}
	.maestro {
		.no-maestro {
		}
		.lithe {
			.answer {
				padding: 0 !important;
				width: 100%;
				@include flexRow(center, flex-start, 6px);
				.lithe-config {
					flex: 1 1 50%;
					@include flexCol(flex-start, flex-start, 6px);
					border-right: 1px solid #e6eaef;
					padding: 10px 24px;
					&:last-child {
						border-right: none;
					}
					.heading {
						color: #718096;
						font-family: "DM Mono";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 12px; /* 100% */
					}
					.value {
						color: #000;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
					}
				}
			}
		}
	}

	.bottom-controller {
		position: fixed;
		bottom: 0;
		width: calc(100vw - 64px - 64px);
		background-color: #fff;
		border-top: 1px solid #e6eaef;
		padding: 16px 0px;
		@include flexRow(center, space-between, 0px);
		.close-tape,
		.add-maestro {
			border-radius: 8px;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.add-maestro {
			color: #25aae5;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 133.333% */
			padding: 14px 24px;
			border: 1px solid #25aae5 !important;
		}
	}
}

.MuiSelect-listbox {
	z-index: 9999 !important;
}
