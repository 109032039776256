.global-parameters {
	height: 100%;
	width: 370px;
	padding: 12px 24px;
	border-right: 1px solid #f1f3f9;
	> h3 {
		color: #475467;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		letter-spacing: 0.5px;
		text-transform: uppercase;
		margin-bottom: 8px;
	}
}

.action-parameters,
.global-parameters {
	.parameter-list {
		margin-top: 8px;
		border: 1px solid #f1f3f9;
		border-radius: 8px;
		list-style: none;
		.parameter {
			padding: 10px 12px;
			@include flexRow(center, space-between, 0);
			border-bottom: 1px solid #f1f3f9;
			&:last-child {
				border-bottom: none;
			}
			&.un-processed {
				label {
					color: rgb(233, 53, 53, 0.8);
				}
				p {
					color: rgb(233, 53, 53, 0.8);
				}
			}
		}
	}
	.parameter-loader {
		height: 100%;
		// width: 100%;
		@include flexCol(flex-start, flex-start, 0);
		padding-top: 20px;
		> h3 {
			color: #101828;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		> p {
			color: var(--black-40, rgba(28, 28, 28, 0.4));
			text-align: right;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
		.skeleton-loader {
			width: 100%;
			margin-top: 10px;
		}
	}
}

.inline-input-editor {
	width: 100%;
	@include flexRow(center, flex-start, 0);
	position: relative;
	.content {
		flex: 1 1 calc(100% - 50px);
		width: 100%;
		@include flexCol(flex-start, flex-start, 2px);
		label {
			color: #475467;
			font-family: "DM Mono", monospace;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
			letter-spacing: -0.96px;
			text-transform: capitalize;
		}
		> p {
			color: #182230;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: calc(100% - 70px);
		}
		.input-field {
			width: 100%;
			border-radius: 4px;
			border: 1px solid #eaedf5;
			background: #fff;
			box-shadow: none;
			height: 40px;
			padding: 0 8px;
			> input {
				height: 40px;
			}

			.MuiInput-endDecorator {
				height: 40px;
				@include flexRow(center, center, 0);
				.input-action-group {
					height: 100%;
					@include flexRow(center, flex-end, 5px);
					> span {
						width: 30px;
						height: 100%;
						@include flexRow(center, center, 0);
						// border-left: 1px solid #eaedf5;
						cursor: pointer;
						svg {
							width: 25px;
							height: 25px;
						}
						&.confirm {
							color: #008538;
						}
					}
				}
			}
		}

		&.highlight-change {
			label {
				color: rgb(233, 53, 53, 0.8);
			}
			> p {
				color: rgb(233, 53, 53, 0.8);
			}
		}
	}
	.edit-icon {
		position: absolute;
		cursor: pointer;
		right: 0;
		svg {
			width: 20px;
			height: 20px;
		}
	}
}
