@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// DM Sans
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");

// DM Mono
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "DM Sans", sans-serif;
}

:root {
	--dark: #344054;
	--dark-2: #667085;
	--white: #ffffff;
	--background: #f5f8fa;
	--alloan-dark-blue: #041d34;
	--alloan-blue: #25aae5;
	--alloan-white: white;
	--alloan-yellow: #fee36f;
	--alloan-purple: #8a78f0;
	--alloan-dark-grey: #8294a5;
	--alloan-light-grey: #e7ecf0;
	--alloan-cyan: #52d0f0;
	--alloan-green: #19c185;
	--alloan-pink: #fb97b4;
	--alloan-red: #fe565b;
	--dark-grey: #293a52;
	--primary: var(--alloan-blue);
}

// Typography
.home-h1 {
	color: var(--alloan-dark-blue);
	font-size: 65px;
	font-style: normal;
	font-weight: 900;
	line-height: 120%; /* 76.8px */
}

.home-h {
	color: var(--alloan-dark-blue);
	font-size: 56px;
	font-style: normal;
	font-weight: 900;
	line-height: 120%; /* 76.8px */
}

.h1-h {
	color: var(--alloan-dark-blue);
	font-size: 42px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 67.2px */
}

.h2-h {
	color: var(--alloan-dark-blue);
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 57.6px */
}

.h3-h {
	color: var(--alloan-dark-blue);
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 42px */
}

.h4-h {
	color: var(--alloan-dark-blue);
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 30px */
}

.h5-h {
	color: var(--alloan-dark-blue);
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 21px */
}

.h6-h {
	color: var(--alloan-dark-blue);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 18px */
}

.p {
	color: var(--Secondary-text, #525560);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 25.6px */
}

.p-sm {
	color: var(--Secondary-text, #525560);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 22.4px */
}

// Typography
@media screen and (max-width: 768px) {
	/* Typography adjustments for mobile devices */
	.home-h1 {
		font-size: 48px;
		line-height: 120%;
	}

	.home-h {
		font-size: 38px;
		line-height: 120%;
	}

	.h1-h {
		font-size: 32px;
		line-height: 120%;
	}

	.h2-h {
		font-size: 28px;
		line-height: 120%;
	}

	.h3-h {
		font-size: 18px;
		line-height: 150%;
	}

	.h4-h {
		font-size: 16px;
		line-height: 150%;
	}

	.h5-h {
		font-size: 12px;
		line-height: 150%;
	}

	.h6-h {
		font-size: 10px;
		line-height: 150%;
	}

	.p {
		font-size: 14px;
		line-height: 160%;
	}

	.p-sm {
		font-size: 12px;
		line-height: 160%;
	}
}

.gradient-text {
	background: var(--gradient-text-1);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.gradient-glass {
	border-radius: 8px;
	border: 2px solid rgba(2, 204, 204, 0.5);
	background: linear-gradient(
		141deg,
		rgba(2, 204, 204, 0.1) 3.22%,
		rgba(2, 66, 51, 0.1) 36.13%,
		rgba(2, 66, 58, 0.1) 54.03%,
		rgba(2, 54, 66, 0.1) 71.45%,
		rgba(2, 204, 204, 0.1) 100%
	);
	box-shadow: 0px 8px 20px 0px rgba(30, 30, 30, 0.35);
}

.gradient-btn {
	border-radius: 5px !important;
	background: linear-gradient(
		94deg,
		#02cccc -41.5%,
		#043c3c 91.42%
	) !important;
	box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1) !important;
}

.primary-btn {
	background: var(--primary) !important;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	min-width: 100px;
}
.MuiButton-colorPrimary {
	background-color: var(--alloan-blue) !important;
	&.disabled {
		color: #9499a4 !important;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 150% */
		background: #f2f3f5 !important;
		border: 1px solid #f2f3f5 !important;
	}
}

@mixin flexRow(
	$align: center,
	$justify: flex-start,
	$gap: 10px
) {
	display: flex;
	flex-direction: row;
	align-items: $align;
	justify-content: $justify;
	gap: $gap;
}

@mixin flexCol(
	$align: center,
	$justify: flex-start,
	$gap: 10px
) {
	display: flex;
	flex-direction: column;
	align-items: $align;
	justify-content: $justify;
	gap: $gap;
}

.App {
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
}

.page-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}
