.multi-factor-page {
	@include flexCol(center, center);
	background-image: url("./../../assets/images/loginBg.png");
	width: 100%;
	min-height: 100vh;
	.multi-factor-card {
		// background: #F5F8FF;
		border-radius: 8px;
		background: #fff;
		// box-shadow: 0px 0px 12px 0px rgba(16, 24, 40, 0.04);
		// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		// box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
		padding: 32px 40px;
		width: 500px;
		@include flexCol(center, center, 15px);
		.verify-process,
		.enroll-process {
			width: 100%;
			@include flexCol(flex-start, flex-start, 5px);
			> h3 {
				color: #000;
				font-size: 24px;
				font-weight: 700;
				line-height: 115%;
			}
			> p {
				color: #718096;
				font-size: 16px;
				font-weight: 400;
				line-height: 150%;
			}
			.enroll-container {
				width: 100%;
				margin-top: 40px;
				@include flexRow(center, flex-start, 20px);
				.qr-container {
					width: 180px;
					height: auto;
					padding: 16px;
					border-radius: 16px;
					border: 1px solid #f0f2f8;
					background: rgba(248, 249, 250, 0.5);
					.qr-code {
						width: 148px;
						height: 148px;
					}
				}
				.manual {
					@include flexCol(flex-start, flex-start, 5px);
					> h4 {
						color: #000;
						font-size: 16px;
						font-weight: 700;
						line-height: 150%;
					}
					> p {
						color: #718096;
						font-size: 14px;
						font-weight: 400;
						line-height: 150%;
						> span {
							font-weight: 500;
						}
					}
				}
			}
			.ask-verification {
				border-top: 1px solid #f0f2f8;
				padding-top: 30px;
				margin-top: 30px;
				width: 100%;
				> h4 {
					color: #000;
					font-size: 16px;
					font-weight: 700;
					line-height: 150%;
				}
				> p {
					color: #718096;
					font-size: 14px;
					font-weight: 400;
					line-height: 150%;
				}
			}
		}
		.error {
			color: #ec3a23;
			font-size: 12px;
			font-weight: 400;
			line-height: 150%;
			text-align: center;
			width: 100%;
			margin-bottom: -12px;
		}
		.btns-container {
			width: 100%;
			margin-top: 24px;
			@include flexRow(center, center, 20px);
			.logout-btn,
			.verification-btn {
				width: 100%;
				padding: 12px 16px;
				border: none;
				border-radius: 8px;
				background-color: var(--primary);
				border: 1px solid var(--primary);
				color: var(--alloan-white);
				letter-spacing: 0.5px;
				font-weight: 400;
				font-family: inherit;
				cursor: pointer;
			}
			.logout-btn {
				background-color: #fff !important;
				color: var(--primary);
				border: 1px solid var(--primary);
			}
			&.mfa-active {
				flex-direction: column-reverse;
			}
		}
	}
}
