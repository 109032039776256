.hammer-column {
	width: 100%;
	height: 100%;
	.hammer-loading {
		width: 100%;
		min-height: 500px;
		.react-loading-skeleton {
			&.even {
				height: 150px;
				margin-bottom: 10px;
			}
		}
	}
	.hammer-output {
		width: 100%;
		@include flexCol(flex-start, flex-start, 20px);
		padding-bottom: 50px;
		> h5 {
			color: #434f61;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
		}
		.hammer-key-outputs {
			width: 100%;
			list-style: none;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 0px;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			.key-output-item {
				border-bottom: 1px solid #f1f3f9;
				border-right: 1px solid #f1f3f9;
				padding: 12px 14px;
				@include flexCol(flex-start, flex-start, 2px);
				.label {
					text-transform: capitalize;
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 12px; /* 100% */
				}
				.value {
					text-transform: capitalize;
					color: #000;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				&.even {
					border-right: none;
				}
				&:last-child {
					border-bottom: none;
				}
				&:nth-last-child(2) {
					&.odd {
						border-bottom: none;
					}
				}
			}
		}

		.all-tranche-container {
			width: 100%;
			@include flexCol(flex-start, flex-start, 10px);
			.tranche {
				width: 100%;
				border: 1px solid #e6eaef;
				border-radius: 8px;
				overflow: hidden;
				.tranche-title {
					width: 100%;
					padding: 12px 14px;
					background: #f1f3f9;
					color: #475467;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					> span {
						text-transform: capitalize;
						color: #475467;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 12px; /* 100% */
					}
					p {
						text-transform: capitalize;
						color: #000;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
					}
				}
				.tranche-data {
					@include flexCol(flex-start, flex-start, 2px);
					width: 100%;
					list-style: none;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 0px;
					.tranche-item {
						border-bottom: 1px solid #f1f3f9;
						border-right: 1px solid #f1f3f9;
						padding: 12px 14px;
						&.even {
							border-right: none;
						}
						@include flexCol(flex-start, flex-start, 2px);
						.label {
							text-transform: capitalize;
							color: #475467;
							font-family: "DM Sans";
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 12px; /* 100% */
						}
						.value {
							text-transform: capitalize;
							color: #000;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
						}
						&.even {
							border-right: none;
						}
						&:last-child {
							border-bottom: none;
						}
						&:nth-last-child(2) {
							&.odd {
								border-bottom: none;
							}
						}
					}
				}
			}
		}

		.chart {
			width: 100%;
			.graph {
				width: 100%;
				max-height: 350px;
				padding: 10px;
				border-radius: var(--radius-xl, 12px);
				background: rgba(205, 228, 254, 0.13);
			}
		}
	}
}
