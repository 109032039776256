.simulation-page {
	width: 100%;
	height: 100vh;
	@include flexCol(flex-start, flex-start, 0px);
	.simulation-layout {
		width: 100%;
		@include flexRow(flex-start, space-between, 0px);
		flex-grow: 1;
		.assumption-panel {
			flex: 0 1 350px;
			padding: 16px 24px;
			height: 100%;
		}
		.assumption-panel {
			border-right: 1.5px solid #f1f3f9;
		}
	}
}

.edit-assumption-drawer {
	position: relative;
	.MuiDrawer-paper {
		height: 100vh;
		background-color: transparent !important;
		@include flexCol(flex-start, space-between 16px);
		padding-top: 16px;
		.close-drawer {
			align-self: flex-end;
			margin-right: 32px;
			cursor: pointer;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			background: rgba(0, 0, 0, 0.3);
			backdrop-filter: blur(4px);
			color: #fff;
			font-size: 22px;
			@include flexRow(center, center, 0px);
		}
	}
}
