.training-data {
	width: 100%;
	height: 100%;
	@include flexRow(flex-start, flex-start, 0px);

	.data-table {
		width: calc(100% - 360px);
		height: 100%;
		overflow: auto;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.no-data-action-prob {
			width: 100%;
			height: 100%;
			@include flexCol(center, center, 0);
		}

		&.data-table-empty {
			grid-template-columns: 1fr;
			.click-to-get-results {
				width: 100%;
				height: 100%;
				@include flexCol(center, center, 0);
				h4 {
					color: #101828;
					font-family: "DM Sans";
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}

		.graph-container {
			padding: 20px 16px;
			border: 0.5px solid #f1f3f9;
			@include flexCol(flex-start, flex-start, 12px);

			.lithe-graphs,
			.prob-graph {
				width: 100%;
				height: 100%;

				.graph-div {
					@include flexCol(center, space-between, 0);
					width: 100%;
					height: 100%;
					.graph-label {
						font-size: 14px !important;
					}
					.graph {
						width: 100%;
						height: 100%;
					}
					canvas {
						width: 100% !important;
						height: auto !important;
					}
				}
			}
		}
		.action-prob-and-summary {
			grid-column: span 2;
			@include flexRow(flex-start, flex-start, 16px);
			padding: 16px;
			padding-right: 20px;
		}
		.action-prob-container {
			flex: 0 0 50%;

			@include flexCol(flex-start, space-between, 12px);
			.graph-selector {
				width: 100%;
			}
			.graph-container {
				width: 100%;
				padding: 0px;
				border: none !important;
				.prob-graph {
					width: 100%;
					height: 100%;
					.graph-div {
						@include flexCol(center, space-between, 0);
						width: 100%;
						height: 100%;
						.graph {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
		.summary {
			flex: 1 1 auto;
			@include flexRow(flex-start, flex-start, 0px);
			border-radius: 8px;
			border: 0.5px solid #e2e7f3;
			overflow: hidden;
			.summary-list {
				width: 100%;
				list-style: none;
				@include flexCol(flex-start, flex-start, 0);

				li {
					width: 100%;
					border-right: 0.5px solid #e2e7f3;
					border-bottom: 0.5px solid #e2e7f3;
					padding: 6px 12px;
					&:last-child {
						border-bottom: none;
					}
					.key {
						color: #475467;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 20px; /* 100% */
					}

					.value {
						color: #182230;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
					}
					&:nth-child(odd) {
						background: #f8f9fc;
					}
				}

				&:last-child {
					li {
						border-right: none;
					}
				}
			}
		}
	}
}
.input-side-bar {
	width: 360px;
	height: 100%;
	padding: 20px 16px;
	padding-bottom: 20px;
	overflow-y: auto;
	border-right: 1px solid #f1f3f9;
	/* Custom scrollbar styles */
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #eaedf5;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #c1c7d0;
		border-radius: 4px;
		border: 2px solid #f1f3f9;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: #a0a7b5;
	}

	@include flexCol(flex-start, flex-start, 12px);
	.range-container,
	.text-container,
	.date-container {
		width: 100%;
		padding: 12px;
		border: 1px solid #f1f3f9;
		border-radius: 8px;
		@include flexCol(flex-start, flex-start, 8px);
		label {
			color: #718096;
			font-family: "DM Mono";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px; /* 100% */
			letter-spacing: -0.24px;
			text-transform: lowercase;
		}

		.input-group,
		.single-input-field {
			width: 100%;
			@include flexRow(center, flex-start, 8px);
			.dash {
				@include flexCol(center, flex-end, 0);
				height: 40px;
				width: 10px;
				color: #9ba0af;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
			}
			.input-field-box {
				width: calc(50% - 13px);
				@include flexCol(flex-start, flex-start, 5px);
				.MuiInput-root {
					width: 100%;
					box-shadow: none;
					border-radius: 8px;
					border: 1px solid #eaedf5;
					background: #fff;
					height: 40px;
				}
				span {
					width: 100%;
					color: var(--Neutral-2, #718096);
					text-align: left;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; /* 137.5% */
				}
				&:last-child {
					span {
						text-align: right;
					}
				}
			}
		}
		.single-input-field {
			width: 100%;
			@include flexCol(flex-start, flex-start, 5px);
			.MuiInput-root {
				width: 100%;
			}
			.date-constraints {
				width: 100%;
				@include flexRow(center, space-between, 8px);
				span {
					color: var(--Neutral-2, #718096);
					text-align: center;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; /* 137.5% */
				}
			}
			.date-picker {
				width: 100%;
				box-shadow: none;
				border-radius: 8px;
				border: 1px solid #eaedf5;
				background: #fff;
				.MuiInputBase-root {
					width: 100%;
					.MuiInputBase-input {
						height: 40px;
						padding: 0 12px;
					}
				}
				.MuiOutlinedInput-notchedOutline {
					display: none;
				}
			}
		}
	}
	.input-query {
		width: 100%;
		@include flexCol(flex-start, flex-start, 8px);
		padding: 12px;
		border-radius: 8px;
		border: 1px solid #f1f3f9;
		position: relative;
		.update-sql-code {
			// position: absolute;
			// top: 12px;
			// right: 12px;
			cursor: pointer;
			@include flexRow(center, flex-end, 5px);
			svg {
				font-size: 20px;
				color: #718096;
			}
			span {
				color: #718096;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 14px; /* 100% */
				letter-spacing: -0.24px;
				text-transform: lowercase;
			}
		}
		.btn-type-primary {
			width: 100%;
			min-height: 40px;
			padding: 8px 12px !important;
			color: #fff;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
			&.disabled {
				background-color: #d0d5dd;
				border: 1px solid #d0d5dd;
			}
		}
		label {
			color: #718096;
			font-family: "DM Mono";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px; /* 100% */
			letter-spacing: -0.24px;
			text-transform: lowercase;
		}
		.sql-code-editor {
			width: 100%;
			height: 100%;
			min-height: 200px;
			overflow-y: auto;
		}
	}
}
