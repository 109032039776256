.input-label-container {
	@include flexCol(flex-start, flex-start, 10px);
	width: 100%;
	> label {
		font-size: 14px;
		font-weight: 500;
		color: var(--dark);
		margin-left: 5px;
	}
	.input-container {
		@include flexRow(center, center, 5px);
		border-radius: 8px;
		border: 1.5px solid #f0f2f8;
		.start-decorator,
		.end-decorator {
			height: 40px;
			width: 40px;
			@include flexRow(center, center, 10px);
			margin: 0px 5px;
		}
		width: 100%;
		height: 45px;
		&:focus-within {
			border: 2px solid var(--primary);
			box-shadow:
				rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
				rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
		}

		.input-field {
			border-radius: 8px;
			padding: 10px !important;
			border: none;
			background: transparent !important;
			width: 100%;
			height: 50px;
			font-size: 14px;
			&::placeholder {
				font-size: 14px;
				color: var(--dark-2);
			}
			&:focus {
				outline: none;
			}
			&:-internal-autofill-selected {
				background-color: transparent !important;
				color: none !important;
			}
		}
	}
	.error {
		color: var(--alloan-red) !important;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 0.5px;
	}
}
