.sanitize-tape-page {
	width: 100%;
	height: 100vh;
	@include flexCol(stretch, flex-start, 0);
	.top-bar {
		padding: 16px 64px;
		@include flexRow(center, space-between);
		.tape-meta {
			@include flexCol(flex-start, flex-start, 5px);
			h2 {
				color: #344054;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #007732;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
		.actions {
			@include flexRow(center, flex-end, 0);
			border: 1px solid #d0d5dd;
			border-radius: 8px;
			position: relative;
			.steps {
				min-width: 110px;
				position: relative;
				@include flexRow(center, center);
				padding: 8px 16px;
				cursor: pointer;
				color: #718096;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				border-right: 1px solid #d0d5dd;
				&:last-child {
					border-right: none;
				}
				&.active {
					color: #344054;
					font-weight: 700 !important;
				}
				&.current-step {
					svg {
						animation: lottie-blink 1.5s linear 0s infinite
							forwards;
					}
				}
				&.completed {
					color: #344054;
					font-weight: 500;
				}
				// .lottie-blink {
				// 	width: 100px !important;
				// 	height: 100px !important;
				// }
			}
		}
	}
	.step-container {
		flex: 1;
		height: calc(100vh - 144px);
	}
	.bottom-bar {
		border-top: 1px solid #f2f4f7;
		background: #fff;
		height: 64px;
		padding: 0 64px;
		@include flexRow(center, space-between);
		.close-tape {
			border-radius: 8px;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.right-side {
			@include flexRow(center, flex-end, 16px);
			.save-btn {
				border-radius: 8px;
				border: 1px solid #d0d5dd !important;
				background: #fff !important;
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			.continue-btn {
				&.disabled {
					opacity: 0.8;
					cursor: not-allowed;
				}
			}
		}
	}
}

.loader-container {
	width: 100%;
	height: 100%;
	@include flexRow(center, center);
	margin: auto auto;
}

.re-sanitize-confirmation-modal {
	border-radius: 16px;
	background: #fff;
	.re-sanitize-btns {
		margin-top: 30px;
		@include flexRow(center, space-between);
		.continue {
		}
		.cancel {
			border-radius: var(--radius-md, 8px);
			border: 1px solid #d92d20 !important;
			background: #d92d20 !important;
			color: #fff;
			&:hover {
				background: #d92d20;
			}
		}
	}
}

@keyframes lottie-blink {
	0% {
		opacity: 0.35;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.35;
	}
}
