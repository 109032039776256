.universal-performance-graph {
	padding: 12px 16px;
	> h4 {
		margin-top: 8px;
		color: #101828;
		font-feature-settings:
			"ss01" on,
			"cv01" on,
			"cv11" on;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
	.graph-mode-selector {
		margin: 12px 0;
		@include flexRow(center, flex-start, 8px);
		span {
			padding: 4px 12px;
			border-radius: 999px;
			border: 1px solid #edeff5;
			background: #fff;
			color: #627188;
			text-align: center;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
			cursor: pointer;

			&.active {
				color: #25aae5;
				border: 1px solid #25aae5;
			}
		}
	}
}
