.key-output-panel {
	width: 100%;
	padding-bottom: 50px;
	.key-output-content {
		width: 100%;
		@include flexCol(flex-start, flex-start, 20px);
		.panel-heading {
			color: #475467;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 133.333% */
			letter-spacing: 0.5px;
			text-transform: uppercase;
		}
		.key-outputs-list {
			width: 100%;
			list-style: none;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 0px;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			li {
				border-bottom: 1px solid #f1f3f9;
				border-right: 1px solid #f1f3f9;
				padding: 12px 14px;
				@include flexCol(flex-start, flex-start, 2px);
				label {
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 12px; /* 100% */
				}
				p {
					color: #000;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				&.even {
					border-right: none;
				}
				&:last-child {
					border-bottom: none;
				}
				&:nth-last-child(2) {
					&.odd {
						border-bottom: none;
					}
				}
			}
		}
	}
	.key-output-graphs {
		.point-graphs {
			width: 100%;
			height: auto;
			margin-top: 10px;
			@include flexCol(flex-start, flex-start, 15px);
			.spinner {
				margin: auto;
				margin-top: 50px;
			}
			.graph-div {
				width: 100%;
				height: auto;
				> h4 {
					padding-left: 12px;
					color: #23262f;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					text-transform: capitalize;
					margin-bottom: 16px;
				}
				.graph {
					width: 100%;
					max-height: 350px;
					padding: 10px;
					border-radius: var(--radius-xl, 12px);
					background: rgba(205, 228, 254, 0.13);
				}
			}
		}
	}
}
