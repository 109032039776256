.error-boundary-page {
	width: 100%;
	padding: 50px;
	height: 100vh;
	&.error-fallback-component {
		height: max-content !important;
	}
	@include flexCol(center, center, 20px);
	.error-icon {
		padding: 10px;
		border-radius: 20px;
		background: #fef7f6;
		img {
			width: 100px;
			height: auto;
		}
	}
	> h3 {
		width: 60%;
		text-align: center;
		color: var(--Neutral-1, #23262f);
		font-family: "DM Sans";
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px; /* 133.333% */
		text-transform: capitalize;
	}
	> p {
		width: 50%;
		color: var(--Neutral-2, #718096);
		text-align: center;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 137.5% */
		word-wrap: break-word;
	}

	.MuiAccordionDetails-content.Mui-expanded {
		margin-top: 10px;
		overflow: auto;
		width: 500px !important;
		height: 250px !important;
		color: var(--Neutral-2, #718096);
		&::-webkit-scrollbar {
			height: 5px;
			width: 4px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 2px;
			background-color: rgb(223, 233, 235);
		}

		&::-webkit-scrollbar-track:hover {
			background-color: rgb(223, 233, 235);
		}

		&::-webkit-scrollbar-track:active {
			background-color: rgb(223, 233, 235);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 2px;
			background-color: #25aae5;
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: #25aae5;
		}

		&::-webkit-scrollbar-thumb:active {
			background-color: #25aae5;
		}
	}
}
