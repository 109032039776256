.lithe-top-bar {
	width: 100%;
	padding: 20px 24px;
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	border-bottom: 1px solid #f1f3f9;

	.title-container {
		@include flexRow(center, flex-start, 16px);
		.go-back-icon {
			cursor: pointer;
			svg {
				width: 40px;
				height: 40px;
			}
		}

		.title {
			width: 100%;
			@include flexCol(flex-start, flex-start, 6px);
			h3 {
				color: #344054;
				font-family: "DM Sans";
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px; /* 122.222% */
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: calc(25vw - 56px);
			}
			.meta {
				@include flexRow(center, flex-start, 6px);
				.asset-class,
				.dataset {
					@include flexRow(center, flex-start, 6px);
					svg {
						width: 24px;
						height: 24px;
					}
					span {
						color: #475467;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 128.571% */
					}
				}
				.dataset {
					&::before {
						content: "";
						background-color: #98a2b3;
						width: 5px;
						height: 5px;
						border-radius: 50%;
					}
				}
			}
		}
	}
	.steps-container {
		@include flexRow(center, center, 0px);

		.step {
			min-width: 140px;
			@include flexRow(center, center, 8px);
			min-height: 45px;
			padding: 8px 16px;
			cursor: pointer;
			border: 1px solid #d0d5dd;
			border-right: none;
			&:last-child {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				border-right: 1px solid #d0d5dd;
			}
			&:first-child {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
			span {
				color: #475467;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			svg {
				width: 15px;
				height: 15px;
			}
		}
	}

	.btn-group {
		width: 100%;
		@include flexRow(center, flex-end, 12px);
		.btn-type-tertiary {
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			min-height: 40px;
			padding: 8px 12px !important;
			color: #434f61;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
		}
		.btn-type-primary {
			min-height: 45px;
			padding: 8px 12px !important;
			color: #fff;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
			@include flexRow(center, center, 8px);
			p {
				@include flexRow(center, center, 5px);
				svg {
					@include flexRow(center, center, 8px);
					width: 18px;
					height: 18px;
				}
			}
			&.disabled {
				background-color: #d0d5dd;
				border: 1px solid #d0d5dd;
			}
		}
	}
}
