.hammer-search-container {
	width: 100%;
	height: 100%;
	@include flexCol(flex-start, flex-start, 0);
	.hammer-top-bar {
		width: 100%;
		height: 72px;
		padding: 16px 24px;
		display: grid;
		grid-template-columns: 1fr 2fr;
		border-bottom: 1px solid #ebf1fa;
		padding-inline: 50px;
		.hammer-top-bar-left {
			@include flexRow(center, flex-start, 12px);
			h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 40px; /* 200% */
				letter-spacing: -0.4px;
			}
		}

		.hammer-top-bar-right {
			@include flexRow(center, flex-end, 12px);
			.search-field {
				flex: 1 1 auto;
				max-width: 400px;
			}
			.alloan-button {
				padding: 10px 12px;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.56px;
			}
			.filter-btn {
				width: 120px !important;
			}
			.upload-btn {
				width: 250px !important;
			}
		}
	}
	.hammer-search-table {
		width: 100%;
		height: calc(100% - 72px);
		@include flexCol(flex-start, flex-start, 0);
		position: relative;
		padding-inline: 50px;
		.table-header {
			width: 100%;
			border-bottom: 1px solid #f9fafb;
			background: #f9fafb;
			.table-row {
				width: 100%;
				overflow: hidden;
				display: grid;
				grid-template-columns: 2fr 2fr 0.5fr;
				&.no-uploaded-by {
					grid-template-columns: 2fr 0.8fr 1fr 1fr 0.5fr;
				}
				> p {
					color: #475467;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 18px; /* 150% */
					text-transform: capitalize;
					text-align: left;
					padding: 10px;
					&:last-child {
						display: flex;
						justify-content: center;
						align-items: center;
					}
					&.asset-class,
					&.date-uploaded {
						@include flexRow(flex-start, flex-start);
					}
				}
				&.lithe-row {
					grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
				}
			}
		}
		.search-results {
			width: 100%;
			min-height: 600px;
			@include flexCol(center, flex-start, 0px);
			.search-loader {
				margin: 100px auto;
				width: 50px;
			}
			.table-row {
				width: 100%;
				overflow: hidden;
				display: grid;
				grid-template-columns: 2fr 2fr 0.5fr;
				border-bottom: 1px solid #f9fafb;
				&.no-uploaded-by {
					grid-template-columns: 2fr 0.8fr 1fr 1fr 0.5fr;
				}
				&:hover {
					background-color: rgb(152, 162, 179, 0.06);
				}

				.search-item {
					cursor: pointer;
					width: 100%;
					overflow: hidden;
					color: #000;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					text-align: left;
					padding: 10px;
					@include flexRow(center, flex-start);
					min-height: 57px;
					&:last-child {
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						width: 50px;
						height: 50px;
						min-height: 50px;
						border-radius: 50%;
						margin: auto;
						.select-tape-icon {
							color: #98a2b3;
							font-size: 20px;
						}

						@include flexRow(center, center);
						&:hover {
							background-color: rgb(152, 162, 179, 0.075);
							.select-tape-icon {
								color: #3ca662;
							}
						}
					}
					&.asset-class,
					&.date-uploaded {
						@include flexRow(center, flex-start);
					}
					&.uploaded-by {
						.uploaded-by-icon {
							width: 35px;
							height: 35px;
							border-radius: 50%;
							overflow: hidden;
							background-color: #7f56d9;
							color: #fff;
							@include flexRow(center, center);
							font-weight: 500;
						}
					}
					&.status > p {
						padding: 3px 10px;
						border-radius: 6px;
						background: rgba(131, 178, 79, 0.1);
						color: #729c44;
						text-align: center;
						font-style: normal;
					}
				}
				&.lithe-row {
					grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
				}
			}
			.no-tapes-found {
				width: 100%;
				text-align: center;
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				margin-top: 100px;
			}
		}
	}
}
