.landing-page {
	width: 100%;
	height: calc(100vh - 60px);
	@include flexCol(center, flex-start, 40px);
	.alloan-logo {
		margin-top: 15dvh;
		height: 80px;
	}
	.search-bar {
		width: 50%;
		@include flexCol(center, center, 35px);
		.search-field {
			width: 100%;
			.input-container {
				@include flexRow(center, space-between, 5px);
				border-radius: 30px !important;
				width: 100%;
				overflow: hidden;
				border: 2px solid var(--primary);
				box-shadow:
					rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
					rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
				.input-field {
					flex: 1;
				}
				.end-decorator {
					width: 130px;
					height: 100%;
					margin: 0 !important;
					@include flexRow(center, center, 20px);
					.end-deco-btns {
						width: 100%;
						height: 100%;
						@include flexRow(center, flex-end, 15px);
						> svg {
							width: 20px;
							height: 20px;
						}
						.search-btn {
							height: 100%;
							background-color: var(--primary);
							padding: 0 20px;
							@include flexRow(center, center, 10px);
							color: var(--white);
						}
					}
				}
			}
		}
		.advance-btn-group {
			width: 100%;
			@include flexRow(center, center, 20px);

			.upload-tape-btn {
				background-color: #fff !important;
				color: var(--primary);
				border: 1px solid var(--primary);
			}
		}
	}
}
