.lithe-approval {
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 24px 5% 100px;
	@include flexCol(flex-start, flex-start, 24px);

	.lithe-approval-meta {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		.meta-info {
			padding: 16px 24px;
			border: 1px solid #f0f2f8;
			@include flexCol(flex-start, flex-start, 3px);
			.lithe-approval-meta-key {
				color: #718096;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				letter-spacing: -0.24px;
			}
			.lithe-approval-meta-value {
				width: 100%;
				color: #000;
				font-feature-settings:
					"ss01" on,
					"cv01" on,
					"cv11" on;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.84px;
				.lithe-databricks-link {
					width: 100%;
					text-decoration: none;
					@include flexRow(center, space-between);
					&:hover {
						text-decoration: underline;
					}
					span {
						color: #25aae5;
					}
				}
			}
		}
	}

	.lithe-refresh-box {
		width: 100%;
		@include flexRow(center, flex-end);
		.alloan-button {
			max-width: 200px;
		}
	}

	.risk-factor-container,
	.probabilities-container,
	.timepoint-container {
		width: 100%;
		@include flexCol(flex-start, flex-start, 12px);
		h4 {
			color: #344054;
			font-feature-settings:
				"ss01" on,
				"cv01" on,
				"cv11" on;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
		}
		.risk-factor-table {
			list-style: none;
			@include flexRow(flex-start, flex-start, 0px);
			li {
				&.header {
					background: #f9fafc;
					h5 {
						color: #000;
						font-family: "DM Mono";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
					}
					ul {
						li {
							span {
								color: #000;
								font-family: "DM Mono";
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px; /* 142.857% */
								letter-spacing: -0.28px;
							}
						}
					}
				}

				h5 {
					padding: 12px 16px;
					overflow: hidden;
					color: #718096;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					border: 1px solid #f0f2f8;
				}
				ul {
					width: 100%;
					list-style: none;
					@include flexCol(flex-start, flex-start, 0px);
					li {
						width: 100%;
						padding: 12px 16px;
						border: 1px solid #f0f2f8;
						@include flexRow(center, flex-start 14px);

						&.checked-breakpoint {
							border-color: #25aae5;
							background-color: #25aae50a;
						}

						.MuiCheckbox-checkbox {
							background-color: transparent;
							border: 1px solid #d0d5dd;
						}

						.Mui-checked {
							.MuiCheckbox-checkbox {
								background-color: #25aae5;
								border-color: #25aae5;
							}
						}

						.breakpoint-value {
							color: #718096;
							font-feature-settings:
								"ss01" on,
								"cv01" on,
								"cv11" on;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
						}
					}
				}
			}
		}
	}
	.timepoint-container {
		width: 100%;
		@include flexRow(center, flex-start);
		.timepoint-select {
			@include flexRow(center, center);
			cursor: pointer;
			background: #fff;
			padding: 10px 12px;
			height: 40px;
			min-width: 150px;
			box-shadow: none;
			> button {
				color: #000;
				text-align: center;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.42px;
				text-transform: capitalize;
			}
		}
	}
	.probabilities-container {
		.probabilities-table {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			.prob {
				border: 1px solid #f0f2f8;
				@include flexCol(flex-start, flex-start, 3px);
				cursor: pointer;
				position: relative;

				.bucket {
					color: #718096;
					font-family: "DM Mono";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.24px;
				}
				.prob-val {
					color: #000;
					font-feature-settings:
						"ss01" on,
						"cv01" on,
						"cv11" on;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.84px;
				}

				.hover-box {
					position: absolute;
					top: -50%;
					left: 0;
					width: max-content;
					height: max-content;
					background: #fff;
					z-index: 100;
					padding: 16px;
					border-radius: 8px;
					box-shadow:
						rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
						rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
					&.hide {
						opacity: 0;
					}
					&.show {
						opacity: 1;
					}

					h5 {
						@include flexRow(center, flex-start, 10px);
						overflow: hidden;
						color: #000;
						text-overflow: ellipsis;
						font-family: "DM Sans";
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 20px; /* 125% */
						margin-bottom: 10px;
					}
					.combinations {
						@include flexCol(flex-start, flex-start, 5px);
						p {
							overflow: hidden;
							color: #475467;
							text-overflow: ellipsis;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 18px; /* 128.571% */
						}
					}
				}

				&:nth-child(6n) {
					.hover-box {
						left: unset;
						right: 0;
					}
				}
			}
		}
	}
	.prob-error-container {
		margin-top: 50px;
		width: 100%;
		@include flexCol(center, center);
		h4 {
			color: var(--Neutral-1, #23262f);
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px; /* 133.333% */
			text-transform: capitalize;
		}
		p {
			color: var(--Neutral-2, #718096);
			text-align: center;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px; /* 137.5% */
		}
	}
}
