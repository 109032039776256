.loan-stats {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 24px 15%;
	@include flexCol(stretch, flex-start, 35px);
	&.loan-stats-tab {
		padding: 24px 16px;
	}
	.heading {
		color: #000;
		font-family: "DM Sans";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 40px; /* 181.818% */
	}

	.description {
		color: #718096;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 125% */
		letter-spacing: -0.32px;
	}

	.balance-stats {
		@include flexRow(center, center, 20px);
		.balance-stats-item {
			flex: 1;
			border-radius: 8px;
			border: 1px solid #f0f2f8;
			background: #fff;
			padding: 12px;
			@include flexRow(center, flex-start, 12px);
			.svg-container {
				width: 40px;
				height: 40px;
				border-radius: var(--radius-sm, 6px);
				background: rgba(17, 17, 17, 0.04);
				@include flexRow(center, center, 0px);
				img {
					width: 24px;
					height: 24px;
				}
			}
			.stats-container {
				h5 {
					color: #23262f;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					opacity: 0.6;
				}
				h3 {
					color: #23262f;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
			}
		}
	}

	.weighted-avg {
		width: 100%;
		@include flexCol(flex-start, flex-start, 20px);
		> h3 {
			color: #23262f;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 125%;
		}
		.list-container {
			width: 100%;
			@include flexRow(flex-start, flex-start, 20px);
			.list {
				width: 50%;
				list-style: none;
				border-radius: 8px;
				border: 1px solid #f0f2f8;
				background: #fff;
				.list-item {
					width: 100%;
					padding: 16px;
					@include flexRow(flex-start, space-between, 20px);
					border-bottom: 1px solid #f0f2f8;
					&:last-child {
						border-bottom: none;
					}
					> p {
						flex: 0 1 calc(50% - 10px);
						overflow: hidden;
						color: rgba(35, 38, 47, 0.6);
						font-size: 14px;
						font-weight: 400;
						line-height: 150%;
						text-transform: capitalize;
						text-overflow: ellipsis;
						white-space: nowrap;

						&.val {
							color: #23262f;
							text-align: right;
							font-weight: 500;
						}
					}
				}
			}
		}
	}

	.loan-stats-graph {
		width: 100%;
		> h3 {
			color: #23262f;
			font-size: 16px;
			font-weight: 500;
			line-height: 125%;
		}
		> p {
			margin-top: 5px;
			color: #718096;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 114.28%; /* 114.286% */
		}
		.statistics {
			margin-top: 15px;
			@include flexRow(stretch, flex-start, 20px);
			.graph {
				width: 60%;
				border-radius: var(--radius-xl, 12px);
				border: 1px solid #f0f2f8;
				padding: 16px;
			}
			.features-list {
				width: 40%;
				border-radius: var(--radius-xl, 12px);
				border: 1px solid #f0f2f8;
				background: #fff;
				@include flexCol(center, flex-start, 0px);
				.feature-item {
					width: 100%;
					padding: 16px;
					@include flexRow(flex-start, space-between, 20px);
					border-bottom: 1px solid #f0f2f8;
					&:last-child {
						border-bottom: none;
					}
					> p {
						overflow: hidden;
						color: rgba(35, 38, 47, 0.6);
						text-overflow: ellipsis;
						font-size: 14px;
						font-weight: 400;
						line-height: 150%;
						text-transform: capitalize;

						&.val {
							color: #23262f;
							text-align: right;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
