.analysis-top-bar {
	width: 100%;
	@include flexRow(center, space-between);
	border-bottom: 1px solid #f1f3f9;
	background: #fff;
	padding: 12px 24px;
	.stb-left {
		@include flexRow(center, flex-start, 16px);
		.stb-back {
			@include flexRow(center, center);
			color: #98a2b3;
			border: 2px solid #98a2b3;
			font-size: 24px;
			height: 40px;
			width: 40px;
			border-radius: 8px;
			cursor: pointer;
		}
		.stb-title {
			@include flexCol(flex-start, flex-start, 0px);
			h3 {
				color: #344054;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #007732;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
	}
	.stb-right {
		@include flexRow(center, flex-end, 16px);

		.user-name {
			padding: 8px 12px;
			@include flexRow(center, flex-start, 8px);
			.text {
				@include flexRow(center, flex-start, 8px);
				.user-icon {
					width: 24px;
					height: 24px;
					border-radius: 50%;
					@include flexCol(center, center, 0);
					color: #fff;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					text-transform: uppercase;
				}
				.title {
					color: #434f61;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}
			}
			.icon {
				width: 16px;
				height: 16px;
				transition: all 0.1s ease-in-out;
				&.rotate90d {
					transform: rotate(90deg);
				}
				&.rotate0d {
					transform: rotate(0deg);
				}
			}
		}

		.stb-action {
			@include flexRow(center, center, 6px);
			cursor: pointer;

			&.btn-type-primary {
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				min-height: 40px;
				padding: 8px 16px !important;
				border-radius: 8px !important;
				border: 1px solid #25aae5;
				background-color: #25aae5;
				&.disabled {
					.MuiButton-startDecorator {
						svg {
							width: 20px;
							height: 20px;
							path {
								stroke: #9499a4 !important;
							}
						}
					}
				}
				.MuiButton-startDecorator {
					svg {
						width: 20px;
						height: 20px;
						path {
							stroke: #fff;
						}
					}
				}
			}
			&.btn-type-secondary {
				border-radius: var(--radius-md, 8px) !important;
				border: 1px solid #25aae5 !important;
				background: #fff !important;
				color: #25aae5;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				min-height: 40px;
				padding: 8px 12px !important;
			}
			&.btn-type-tertiary {
				border-radius: var(--radius-md, 8px) !important;
				border: 1px solid #d0d5dd !important;
				background: #fff !important;
				color: #434f61;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				min-height: 40px;
				padding: 8px 12px !important;
			}
			&.stats {
				padding: 8px 12px;
				> p {
					color: #434f61;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}
			}

			&.reset {
				border: 1px solid #e93535 !important;
				background: #e93535 !important;
				color: #fff;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
			}

			&.histogram {
				padding: 8px;
			}
		}
	}
}

.user-menu {
	max-width: 250px;
	min-width: 150px;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	> li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		padding: 12px 16px;
		.menu-item {
			width: 100%;
			@include flexRow(center, flex-start, 8px);
			.user-icon {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				@include flexCol(center, center, 0);
				color: #fff;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				text-transform: uppercase;
			}
			.title {
				color: #718096;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
			}
		}
		&:hover {
			background-color: rgba(
				120,
				120,
				120,
				0.05
			) !important;
			cursor: pointer;
		}
	}
}

.tape-stats-drawer,
.cashflow-drawer-container {
	position: relative;
	.MuiDrawer-paper {
		height: 100vh;
		background-color: transparent !important;
		@include flexCol(flex-start, space-between 16px);
		padding-top: 16px;
		.close-drawer {
			align-self: flex-end;
			margin-right: 32px;
			cursor: pointer;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			background: rgba(0, 0, 0, 0.3);
			backdrop-filter: blur(4px);
			color: #fff;
			font-size: 22px;
			@include flexRow(center, center, 0px);
		}
		.loan-stats {
			background-color: #fff;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
	}
}

.simulation-drawer-container,
.edit-maestro-drawer-container {
	position: relative;
	.MuiDrawer-paper {
		height: 100vh;
		width: 50%;
		background-color: transparent !important;
		@include flexCol(flex-start, space-between 16px);
	}
}

.edit-maestro-drawer-container.vijay-hack {
	.MuiDrawer-paper {
		width: 65%;
	}
}

.reset-modal {
	.title {
		color: #000;
		font-family: "DM Sans";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px; /* 136.364% */
		margin-bottom: 6px;
	}
	.content {
		color: #718096;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
	}
	.btn-group {
		margin-top: 20px;
		@include flexRow(center, space-between, 16px);
		.btn-type-secondary {
			flex: 1;
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #e93535 !important;
			background: #e93535 !important;
			color: #fff;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			min-height: 40px;
			padding: 14px 24px !important;
		}
		.btn-type-tertiary {
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			color: #434f61;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			min-height: 40px;
			padding: 14px 24px !important;
		}
	}
}
