.coach-mark {
	position: absolute;
	border-radius: 6px;
	background: var(--dark-background, #181818);
	box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.12);
	color: #fff;
	padding: 12px;
	min-width: 100px;
	width: max-content;
	z-index: 1000;
	&.pos-bottom {
		top: calc(100% + 10px);
		left: 0;
		&::before {
			content: "";
			position: absolute;
			top: -25px;
			left: 30px;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-radius: 6px;
			border-color: transparent transparent #181818
				transparent;
		}
	}
	&.pos-bottom-right {
		top: calc(100% + 15px);
		right: 0;
		&::before {
			content: "";
			position: absolute;
			top: -25px;
			right: 0px;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-radius: 6px;
			border-color: transparent transparent #181818
				transparent;
		}
	}
}
