.simulation-top-bar {
	width: 100%;
	@include flexRow(center, space-between);
	border-bottom: 1px solid #f1f3f9;
	background: #fff;
	padding: 16px 24px;
	.stb-left {
		@include flexRow(center, flex-start, 16px);
		.stb-back {
			@include flexRow(center, center);
			color: #98a2b3;
			border: 2px solid #98a2b3;
			font-size: 24px;
			height: 40px;
			width: 40px;
			border-radius: 8px;
			cursor: pointer;
		}
		.stb-title {
			@include flexCol(flex-start, flex-start, 2px);
			h3 {
				color: #344054;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #007732;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}
	}
	.stb-right {
		@include flexRow(center, flex-end, 16px);
		.stb-action {
			@include flexRow(center, center);
			&.btn-type-primary {
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				padding: 10 14px !important;
			}
			&.btn-type-secondary {
				border-radius: var(--radius-md, 8px) !important;
				border: 1px solid #25aae5 !important;
				background: #fff !important;
				color: #25aae5;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				padding: 10 14px !important;
			}
			&.btn-type-tertiary {
				border-radius: var(--radius-md, 8px) !important;
				border: 1px solid #d0d5dd !important;
				background: #fff !important;
				color: #434f61;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				padding: 10 14px !important;
			}
		}
	}
}
