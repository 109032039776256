.lithe-search-page {
	.tape-search-filter {
		width: 60%;
		@include flexRow(center, flex-start, 15px);
		.upload-btn {
			width: max-content;
			white-space: nowrap;
			svg {
				font-size: 20px;
			}
		}
	}
}
