.user-management-page {
	padding-inline: 48px;
	padding-top: 16px;
	.head {
		width: 100%;
		@include flexRow(center, space-between, 20px);
		.search-or-add {
			width: 50%;
			height: 50px;
			@include flexRow(center, space-between, 20px);
			.search-input {
				width: 100%;
				flex: 1 1 auto;
				height: 40px;
				padding: 0px;
				border: none !important;
				background: #fff !important;
				box-shadow: none !important;
				border-bottom: 2px solid #fff !important;
				color: #000;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
				border-radius: 0 !important;
				@include flexRow(center flex-start, 0px);
				input {
					height: 100%;
					padding-inline: 16px;
					flex: 1 1 auto;
					&::placeholder {
						color: #718096;
					}
				}
				svg {
					font-size: 20px;
					color: #718096;
				}
				&:focus-within::before {
					border: none;
					outline: none !important;
					box-shadow: none !important;
					border-bottom: 2px solid var(--primary);
				}
				&.Mui-focused {
					border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}
			}
			.add-user-button {
				width: 150px;
				height: 40px;
			}
		}
	}

	.search-results {
		width: 100%;
		margin-top: 20px;
		padding-bottom: 80px;
		@include flexCol(center, flex-start, 20px);
		.search-results-header,
		.search-results-body {
			width: 100%;
			ul {
				width: 100%;
				list-style: none;
				display: grid;
				grid-template-columns: 1.5fr 1.2fr 1fr 1fr 1fr 100px;
				height: 70px;
				border-bottom: 1px solid #f9fafb;
				li {
					height: inherit;
					@include flexRow(center, flex-start, 0px);
					overflow: hidden;
					color: #000;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					padding-inline: 16px;
					&.name {
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						.user-icon {
							margin-right: 10px;
							width: 35px;
							height: 35px;
							border-radius: 50%;
							overflow: hidden;
							background-color: #7f56d9;
							color: #fff;
							@include flexRow(center, center);
							font-weight: 500;
						}
					}
				}
			}
		}
		.search-results-header {
			ul {
				background: #f9fafb;
			}
		}

		.search-results-body {
			> ul {
				cursor: pointer;
				&:hover {
					background-color: rgb(152, 162, 179, 0.06);
				}
				.role-item {
					@include flexRow(center, flex-start, 5px);
				}
				.tenant-item {
					@include flexRow(center, flex-start, 5px);
					.all {
						text-transform: capitalize;
					}
					.tenant-and-roles {
						list-style: none;
						width: 100%;
						@include flexCol(flex-start, flex-start, 5px);
						li {
							padding-inline: 0 !important;
							@include flexRow(center, flex-start, 5px);
							.tenant-name {
								text-transform: capitalize;
								font-weight: 500;
							}
							.tenant-role {
								padding: 2px 8px;
								border-radius: 8px;
								text-align: center;
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px; /* 166.667% */
								&.Admin {
									background: rgba(245, 166, 34, 0.1);
									color: #f5a622;
								}
								&.User {
									background: rgba(37, 170, 229, 0.1);
									color: #25aae5;
								}
							}
						}
					}
				}
				.action-item {
					.action-menu {
						background-color: transparent;
						border: none;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						@include flexRow(center, center);
						position: relative;
						&:hover {
							background-color: rgb(255, 255, 255);
						}
					}
				}
			}
		}
	}
}

.create-edit-user-modal {
	width: 100%;
	.top {
		width: 300px;
		@include flexRow(center, flex-start, 50px);
		.circle {
			position: relative;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #fff;
			border: 1px solid #b9b9c3;
			&.done {
				background-color: #25aae5;
				border-color: #25aae5;
			}
			&::after {
				content: "";
				position: absolute;
				left: 15px;
				top: 4px;
				width: 40px;
				height: 1px;
				background-color: #b9b9c3;
				display: block;
				opacity: 0.5;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	@include flexCol(flex-start, flex-start, 20px);
	.basic-details,
	.global-role-selection,
	.tenant-selection {
		width: 100%;
		@include flexCol(flex-start, flex-start, 20px);
		.title {
			h4 {
				color: #000;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #718096;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px; /* 137.5% */
			}
		}
	}
	.global-role-selection {
		.role-selection {
			@include flexCol(center, flex-start, 20px);
			.role-option {
				cursor: pointer;
				border-radius: 8px;
				border: 1px solid #f0f2f8;
				@include flexCol(flex-start, flex-start, 5px);
				padding: 12px 14px;
				svg {
					width: 24px;
					height: 24px;
				}
				h4 {
					color: #000;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 150% */
				}
				p {
					color: #718096;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				&.selected {
					border-radius: 8px;
					border: 1px solid #25aae5;
					svg {
						path {
							fill: #25aae5 !important;
						}
					}
					h4 {
						color: #25aae5;
					}
				}
			}
		}
	}

	.tenant-selection {
		.tenant-selection-container {
			width: 100%;
			@include flexRow(center, flex-start, 12px);
			.tenant-select {
				flex: 1 1 auto;
				height: 50px;
				border-radius: 8px;
				border: 1px solid #f0f2f8;
				background: #fff;
				box-shadow: none;
			}
			.role-select {
				width: 120px;
				height: 50px;
				border-radius: 8px;
				border: 1px solid #f0f2f8;
				background: #fff;
				box-shadow: none;
			}
			.primary {
				width: 50px;
				height: 50px;
			}
		}
		.selected-tenants {
			width: 100%;
			@include flexRow(center, flex-start, 10px);
			flex-wrap: wrap;
			.tenant {
				width: max-content;
				min-width: 120px;
				padding: 10px 12px;
				border-radius: 8px;
				border: 1px solid #f0f2f8;
				background: #f8f9fc;
				@include flexRow(center, space-between, 10px);
				.info {
					@include flexCol(flex-start, flex-start, 5px);
					.name {
						color: #000;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 12px; /* 85.714% */
						letter-spacing: -0.28px;
						text-transform: lowercase;
					}
					.role {
						color: #718096;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 128.571% */
						letter-spacing: -0.28px;
					}
				}
				svg {
					width: 20px;
					height: 20px;
					color: #718096;
					cursor: pointer;
				}
			}
		}
	}

	.btn-group {
		width: 100%;
		@include flexRow(center, flex-start, 10px);
		.tertiary {
			width: 50px;
			height: 50px;
			@include flexRow(center, center);
			svg {
				width: 25px;
				height: 25px;
				color: #475467;
			}
		}
		.primary {
			height: 50px;
			flex: 1 1 auto;
		}
	}
}
