.structure-drawer {
	width: 100%;
	height: calc(100vh - 50px);
	overflow-y: auto;
	background-color: #fff;
	border-radius: 15px 15px 0 0;
	padding: 16px 64px;
	@include flexCol(flex-start, flex-start, 20px);
	.structure-drawer-header {
		width: 100%;
		@include flexRow(center, space-between);

		.title {
			@include flexCol(flex-start, flex-start, 2px);
			h3 {
				color: #344054;
				font-family: "DM Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 120% */
			}
			p {
				color: #98a2b3;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}
		}

		.update-structure-btn {
			@include flexRow(center, center, 16px);
			.process-btn {
				&.disable {
					background-color: #f1f3f9 !important;
					color: #98a2b3 !important;
					cursor: not-allowed;
				}
			}
		}
	}

	.structure-loader {
		@include flexRow(center, center, 0px);
		width: 100%;
		height: 100%;
	}

	.structure-drawer-content {
		width: 100%;
		border-radius: 16px 16px 0 0;
		border: 1px solid #e6eaef;
		background: #fff;
		@include flexCol(flex-start, flex-start, 0px);

		.add-new-tranche {
			width: 100%;
			padding: 16px 24px;
			@include flexRow(center, flex-end, 0px);
			.add-new-tranche-btn {
				border-radius: 8px;
				border: 1px solid #25aae5 !important;
				background-color: #fff !important;

				/* Shadows/shadow-xs */
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				color: #25aae5;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 133.333% */
			}
		}
		.row {
			width: 100%;
			@include flexRow(stretch, flex-start, 0px);
			border-bottom: 1px solid #e6eaef;
			&:last-child {
				border-bottom: none;
			}
			&.row-type-tranche {
			}
			&.row-type-residual {
				.tranche-title {
					border-bottom: 1px solid #e6eaef;
				}
			}
			&.row-residual-edit {
				border-top: 1px solid #e6eaef;
			}
			&.row-tranche-edit {
				.tranche-data-container {
					flex: 0 0 calc(70% - 60px);
				}
			}
			.tranche-title {
				flex: 1 1 30%;
				padding: 16px 20px;
				color: #0c111d;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
			}
			.tranche-data-container {
				flex: 1 1 70%;
				@include flexRow(stretch, flex-start, 0px);
				flex-wrap: wrap;

				&.hammer-container {
					@include flexRow(center, flex-end, 0px);
					border-left: 1px solid #e6eaef;
					padding: 11px 20px 11px 24px;
					.hammer-type-select {
						flex: 1;
						max-width: 250px;
					}
				}

				.tranche-data {
					width: calc((100%) / 3);
					border-left: 1px solid #e6eaef;
					background: #fff;
					@include flexRow(center, space-between, 0px);
					padding: 11px 20px 11px 24px;

					&.tranche {
						border-bottom: 1px solid #e6eaef;
						&.corner,
						&.corner-last {
							flex-grow: 1;
							&.row-number-1 {
								border-radius: 0 16px 0 0;
								&.edit-mode {
									border-radius: 0;
									padding-right: 24px;
									border-right: none;
								}
							}
						}
						&.last-row {
							border-bottom: none;
						}
						&.last {
							border-right: 1px solid #e6eaef;
						}
					}

					&.residual {
						border-bottom: 1px solid #e6eaef;
						&.corner,
						&.corner-last {
							flex-grow: 1;
							padding-right: 80px;
						}
						&.last {
							border-right: 1px solid #e6eaef;
						}
						&.last-row {
							border-bottom: none;
						}
					}
					.data {
						@include flexCol(flex-start, flex-start, 5px);
						.data-key {
							color: #718096;
							font-family: "DM Mono", monospace;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 12px; /* 100% */
						}
						.data-value {
							color: #000;
							font-family: "DM Mono", monospace;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							letter-spacing: -0.28px;
						}
					}
					.edit-icon {
						cursor: pointer;
					}
				}
			}
			.delete {
				width: 60px;
				border-left: 1px solid #e6eaef;
				border-radius: 0 16px 0 0;
				@include flexRow(center, center, 0px);
				cursor: pointer;
			}
		}
	}
}

.MuiSelect-listbox {
	z-index: 9999 !important;
}
