.analysis-side-bar {
	border-right: 1px solid #f1f3f9;
	background: #fff;
	flex: 0 0 370px;
	height: 100%;
	.model-selection-container {
		padding: 12px 20px;
		padding-bottom: 20px;
		@include flexCol(flex-start, flex-start, 16px);
		// border-bottom: 1px solid #f1f3f9;
		.model-name {
			width: 100%;
			padding: 8px 12px;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			background: #fff;
			@include flexRow(center, space-between, 0);
			.text {
				@include flexCol(flex-start, flex-start, 0px);
				> p {
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.24px;
					text-transform: capitalize;
				}
				> h4 {
					color: #182230;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
			.icon {
				width: 16px;
				height: 16px;
				transition: all 0.1s ease-in-out;
				&.rotate90d {
					transform: rotate(90deg);
				}
				&.rotate0d {
					transform: rotate(0deg);
				}
			}
		}

		.scenario-selector {
			width: 100%;
			height: 54px;
			border: 1px solid #f0f2f8;
			background: #fff;
			border-radius: 8px;
			@include flexRow(center, space-between, 0);

			.price-point {
				@include flexCol(flex-start, center, 3px);
				padding: 10px;
				h4 {
					color: #182230;
					font-family: "DM Sans";
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 100% */
					letter-spacing: -0.8px;
				}
				span {
					color: var(
						--colors-text-text-secondary-700,
						#344054
					);
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 14px; /* 116.667% */
					text-transform: lowercase;
				}
			}
			.all-points {
				flex: 1 1 auto;
				@include flexRow(center, flex-end, 0);
				> span {
					text-transform: capitalize;
					@include flexRow(center, center, 0);
					height: 54px;
					border-right: 1px solid #f0f2f8;
					padding: 12px 20px;
					color: var(
						--colors-text-text-secondary-700,
						#344054
					);
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					&:last-child {
						border-right: none;
					}
					&.active {
						background: #f6f9fe;
						color: #25aae5;
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 142.857% */
						letter-spacing: -0.28px;
					}
					&.scenario {
						cursor: pointer;
					}
				}
			}
		}

		.key-output-content {
			width: 100%;
			.key-outputs-list {
				width: 100%;
				list-style: none;
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 0px;
				border-radius: 8px;
				border: 1px solid #f1f3f9;
				height: 54px;
				li {
					border-right: 1px solid #f1f3f9;
					padding: 4px 12px;
					height: 100%;
					@include flexCol(flex-start, center, 5px);
					label {
						color: #475467;
						font-family: "DM Mono", monospace;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 14px; /* 127.273% */
						text-transform: uppercase;
					}
					p {
						color: #182230;
						font-family: "DM Sans";
						font-size: 13px;
						font-style: normal;
						font-weight: 500;
						line-height: 18px; /* 138.462% */
					}
					&:last-child {
						border-right: none;
					}
				}
			}
		}
		.btn-group {
			width: 100%;
			@include flexRow(center, flex-start, 15px);
			.view-cashflow {
				@include flexRow(center, center, 0px);
				.MuiButton-startDecorator {
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			.btn-type-secondary {
				width: 100%;
				border-radius: 8px !important;
				border: 1px solid #25aae5 !important;
				background: #fff !important;
				color: #25aae5 !important;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 114.286% */
				padding: 12px 16px;
			}
			.btn-type-primary {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				background: #25aae5;
				color: #fff;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				text-transform: capitalize;
				padding: 12px 16px;
			}
		}
	}
}
