.create-lithe-modal {
	margin: auto;
	width: 550px;
	border-radius: 12px;
	padding: 20px 28px;
	background-color: #fff !important;
	@include flexCol(flex-start, flex-start, 20px);
	.modal-header {
		width: 100%;
		h3 {
			color: #000;
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px; /* 136.364% */
		}
	}
	.lithe-meta,
	.lithe-creation-methods {
		width: 100%;
		@include flexCol(flex-start, flex-start, 16px);
		.text,
		label {
			overflow: hidden;
			color: #000;
			text-overflow: ellipsis;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: 20px; /* 142.857% */
		}
	}
	.lithe-meta {
		.input-box {
			width: 100%;
			@include flexCol(flex-start, flex-start, 5px);
			.input-field,
			.asset-class-selector {
				width: 100%;
				border-radius: 4px;
				border: 1px solid #eaedf5;
				background: #fff;
				box-shadow: none;
				padding: 10px 20px;
				height: 45px;
			}
			.error {
				width: 100%;
				padding-left: 10px;
				text-align: left;
				color: var(--alloan-red) !important;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0.5px;
			}
			.switch-box {
				@include flexRow(center, flex-start, 8px);
				span {
					cursor: pointer;
					text-align: center;
					color: #475467;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
					padding: 4px 12px;
					&.active {
						border-radius: 8px;
						border: 1px solid #25aae5;
						color: #fff;
						background: #25aae5;

						/* Shadows/shadow-xs */
						box-shadow: 0px 1px 2px 0px
							rgba(16, 24, 40, 0.05);
					}
				}
			}
		}
	}
	.lithe-creation-methods {
		.dataset-method,
		.manual-method {
			width: 100%;
			border-radius: 8px;
			border: 1px solid #eaedf5;
			background: #fff;
			@include flexRow(center, flex-start, 16px);
			padding: 16px 14px;
			cursor: pointer;
			&.selected {
				border: 1px solid #25aae5;
			}
			span {
				width: 28px;
				height: 28px;
				svg {
					width: 28px;
					height: 28px;
				}
			}
			.description {
				h3 {
					overflow: hidden;
					color: #000;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 125% */
				}
				p {
					overflow: hidden;
					color: #000;
					text-overflow: ellipsis;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 300;
					line-height: 20px; /* 142.857% */
				}
			}
		}
	}
	.btn-group {
		width: 100%;
		@include flexRow(center, space-between, 16px);
		.btn-type-tertiary {
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			min-height: 40px;
			padding: 14px 24px !important;
			color: #475467;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		.btn-type-primary {
			flex: 1;
			min-height: 40px;
			padding: 14px 24px !important;
			color: #fff;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
			text-transform: capitalize;
			&.disabled {
				background-color: #d0d5dd;
				border: 1px solid #d0d5dd;
			}
		}
	}
}
