.loader {
	@include flexCol(center, center, 10px);
}

.spinner {
	animation: spin 0.75s infinite linear;
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
