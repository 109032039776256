.alloan-button {
	width: 100%;
	border-radius: 8px !important;
	text-transform: capitalize;
	@include flexRow(center, center, 8px);
	min-height: 40px !important;
	&.primary {
		border: 1px solid #25aae5;
		background: #25aae5;
		color: #fff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
	&.secondary {
		border: 1px solid #25aae5 !important;
		background: #fff !important;
		color: #25aae5 !important;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 114.286% */
	}
	&.tertiary {
		border: 1px solid #d0d5dd !important;
		background: #fff !important;
		color: #292d32 !important;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 114.286% */
	}
}
