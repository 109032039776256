.chartjs-graphs {
	width: 100%;
	height: auto;
	.graph-div {
		width: 100%;
		height: auto;
		.graph-label {
			color: #1c1c1c;
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px; /* 150% */
		}
		canvas {
			width: 100% !important;
			height: auto;
		}
	}

	.graph-loader {
		margin-top: 50px;
		width: 100%;
		height: 100%;
		gap: 20px;
		.loader__text {
			> h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			> p {
				color: var(--black-40, rgba(28, 28, 28, 0.4));
				text-align: right;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}
	}
	.no-data {
		width: 100%;
		height: max-content;
		@include flexCol(center, center, 0);
		color: #1c1c1c;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}

.prob-graph {
	width: 100%;
	height: 100%;
	.graph-loader {
		margin-top: 0;
		width: 100%;
		height: 100%;
		@include flexCol(center, center, 0);
	}
	.graph-div {
		width: 100%;
		height: 100%;
		@include flexCol(flex-start, center, 0);
		canvas {
			width: 100% !important;
			height: auto !important;
			max-height: max(40vh, 450px);
		}
	}
}
