.save-point-panel {
	border-left: 1px solid #f8f9fc;
	width: 250px;
	height: 100%;
	padding: 16px 18px;
	@include flexCol(flex-start, flex-start, 16px);
	.no-save-point {
		width: 100%;
		height: 100%;
		@include flexCol(center, center, 16px);
		> span {
			border-radius: 12px;
			background: rgba(37, 170, 229, 0.1);
			@include flexRow(center, center, 8px);
			width: 40px;
			height: 40px;
			padding: 8px;
			flex-shrink: 0;
		}
		> h4 {
			color: #0c111d;
			text-align: center;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		> p {
			color: #98a2b3;
			text-align: center;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}
	> h3 {
		color: #475467;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		letter-spacing: 0.5px;
		text-transform: uppercase;
	}
	.search-bar {
		width: 100%;
		.search-input {
			width: 100%;
			flex: 1 1 auto;
			height: 36px;
			padding: 10px 12px;
			border: 1px solid #eaedf5;
			background: #fff !important;
			box-shadow: none !important;
			color: #000;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
			@include flexRow(flex-start, flex-start, 0px);
			input {
				height: 100%;
				flex: 1 1 auto;
				&::placeholder {
					color: #718096;
				}
			}
			&:focus-within::before {
				border: 1px solid #eaedf5;
				// border: none !important;
				outline: none !important;
				box-shadow: none !important;
			}
			&.Mui-focused {
				border: none !important;
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}
	.tag-list {
		list-style: none;
		> li {
			padding: 12px 16px;
			@include flexRow(center, flex-start, 12px);
			cursor: pointer;
			.check-box {
				border: 1.5px solid #d0d5dd;
				border-radius: 5px;
				width: 20px;
				height: 20px;
				@include flexRow(center, center, 0px);
				svg {
					font-size: 14px;
					color: #4c5667;
				}
			}
			.tag-details {
				@include flexCol(flex-start, flex-start, 0px);
				p {
					color: #000;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 18px; /* 128.571% */
					letter-spacing: -0.28px;
				}
				span {
					color: #4c5667;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.24px;
				}
			}
		}
	}
}
