.structure-input-box {
	.structure-input {
		height: 30px;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		background-color: #fff;
		box-shadow: none;
		border: none;
		width: 100%;
		&:focus-within {
			outline: none;
			border: none;
			box-shadow: none;
			border-bottom: 1px solid #f1f3f9;
		}
	}
}
