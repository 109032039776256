.action-prob-workbench {
	width: 100%;
	height: 100%;
	border-right: 1px solid #f1f3f9;
	padding: 12px 16px;
	@include flexCol(flex-start, flex-start, 15px);
	.action-prob-workbench-header {
		margin-bottom: 8px;
		@include flexRow(center, space-between, 8px);
		.action-prob-workbench-header-title {
			color: #101828;
			font-feature-settings:
				"ss01" on,
				"cv01" on,
				"cv11" on;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.MuiSelect-root {
			padding: 8px 12px !important;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			background: #fff;
			width: 200px;
			box-shadow: none !important;
			height: 32px !important;
			button {
				color: #182230;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}
	}
	.graph-mode-selector {
		@include flexRow(center, flex-start, 8px);
		span {
			padding: 4px 12px;
			border-radius: 999px;
			border: 1px solid #edeff5;
			background: #fff;
			color: #627188;
			text-align: center;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}
	.graph-container {
		width: 100%;
		flex: 1;
	}
	.workbench-loader {
		@include flexCol(center, center, 0);
		width: 100%;
		flex: 1;
		.graph-loader {
			width: 100%;
			height: 100%;
			gap: 20px;
			.loader__text {
				> h3 {
					color: #101828;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
				> p {
					color: var(--black-40, rgba(28, 28, 28, 0.4));
					text-align: right;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
				}
			}
		}
	}
}
