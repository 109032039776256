.save-point-modal {
	border-radius: 12px;
	padding: 20px 28px;
	@include flexCol(flex-start, flex-start, 20px);
	.header {
		width: 100%;
		@include flexCol(flex-start, flex-start, 6px);
		h3 {
			color: #000;
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px; /* 136.364% */
		}
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 125% */
			letter-spacing: -0.32px;
		}
	}
	.input-container {
		width: 100%;
		@include flexCol(flex-start, flex-start, 8px);
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.all-applied-tags {
			width: 100%;
			@include flexRow(center, flex-start, 8px);
			.applied-tag {
				border-radius: 6px;
				background: #f8f9fc;
				padding: 6px 8px;
				@include flexRow(center, flex-start, 8px);
				svg {
					color: #acb5c3;
					cursor: pointer;
				}
			}
		}
		.point-value {
			width: 100%;
			flex: 1 1 auto;
			height: 48px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border: 1px solid #eaedf5;
			background: #fff !important;
			box-shadow: none !important;
			color: #000;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
			@include flexRow(flex-start, flex-start, 0px);
			input {
				height: 100%;
				flex: 1 1 auto;
				&::placeholder {
					color: #718096;
				}
			}
		}
		.simulation-input-box {
			width: 100%;
			height: 48px;
			@include flexRow(center, flex-start, 0px);
			.simulation-input {
				flex: 1 1 auto;
				height: 48px;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				border: 1px solid #eaedf5;
				background: #fff !important;
				box-shadow: none !important;
				color: #000;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
				@include flexRow(flex-start, flex-start, 0px);
				input {
					height: 100%;
					flex: 1 1 auto;
					&::placeholder {
						color: #718096;
					}
				}
				&:focus-within::before {
					border: 1px solid #eaedf5;
					// border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}
				&.Mui-focused {
					border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}
			}
			.add-task {
				height: 48px;
				width: 100px;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}
		}
		position: relative;
		.tag-list {
			z-index: 10;
			width: 100%;
			position: absolute;
			top: calc(100% + 8px);
			left: 0;
			max-height: 250px;
			overflow-y: auto;
			overflow-x: hidden;
			@include flexCol(flex-start, flex-start, 8px);
			list-style: none;
			border-radius: 8px;
			border: 1px solid #f2f4f7;
			background: #fff;

			/* Dropdown */
			box-shadow:
				0px 12px 16px -4px rgba(16, 24, 40, 0.08),
				0px 4px 6px -2px rgba(16, 24, 40, 0.03);

			&.hide {
				display: none;
			}
			&.show {
				display: block;
			}

			> li {
				color: #344054;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				padding: 16px 20px;
				width: 100%;
				@include flexRow(center, flex-start, 8px);
				&:hover,
				&.selected {
					background: #f8f9fc;
					cursor: pointer;
				}
			}
		}
	}
	.bottom-btn {
		margin-top: 15px;
		width: 100%;
		@include flexRow(flex-start, space-between, 16px);
		.cancel-btn {
			border-radius: 10px;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			padding: 14px 24px;
			color: #475467;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		.save-btn {
			@include flexRow(center, center, 10px);
			flex: 1 1 auto;
			padding: 14px;
			color: #fff;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
			text-transform: capitalize;
			border-radius: 10px;
			background: #25aae5;
		}
	}
}
