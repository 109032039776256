.edit-mapping-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	padding: 40px;
	border-radius: 12px;
	box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
	padding: 28px;
	@include flexCol(center, center, 16px);
	border: none !important;
	width: 500px;

	.header {
		width: 100%;
		@include flexRow(center, space-between, 16px);
		margin-bottom: 20px;
		h3 {
			width: max-content;
		}
		.actions {
			@include flexRow(center, flex-end, 16px);

			> span {
				@include flexCol(center, center);
				border-radius: 50%;
				border: 1px solid #d0d5dd;
				font-size: 20px;
				color: #718096;
				width: 40px;
				height: 40px;
				&.view-tape {
					cursor: pointer;
				}
			}
		}
	}
	.sub-heading {
		align-self: flex-start;
		color: #718096;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		margin-bottom: -8px;
		&.validation-constraints {
			@include flexCol(flex-start, flex-start, 5px);
			.constraints-container {
				@include flexRow(center, flex-start, 8px);
				flex-wrap: wrap;
				.constraints-box {
					border-radius: 6px;
					background: #f8f9fc;
					padding: 6px 8px;
				}
			}
		}
	}
	.see-examples {
		@include flexRow(center, flex-start, 10px);
		color: #718096;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		align-self: flex-start;
		cursor: pointer;
		svg {
			font-size: 16px;
		}
	}
	.column-name {
		width: 100%;
		border-radius: 12px;
		border: 0.5px solid #dce1ef;
		background: #fff;
		padding: 24px;
		h3 {
			color: #000;
			font-family: "DM Sans";
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 133.333% */
			letter-spacing: -0.36px;
		}
	}

	> svg {
		color: #dce1ef;
		width: 32px;
		height: 32px;
	}

	.editor {
		width: 100%;
		border-radius: 12px;
		border: 0.5px solid #dce1ef;
		background: #fff;
		overflow: auto;

		.mapping-text-editor {
			padding: 24px;
			border-radius: 12px;
			width: 100%;
			border: none;
			font-family: "DM Mono", monospace;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			letter-spacing: -0.28px;
			color: #000;
			resize: none;
			outline: none;
		}

		.mapping-code-editor {
			width: 100%;
			height: 100%;
			padding: 12px;
			border-radius: 12px;
		}
	}

	.error-box {
		> p {
			color: #f24e1e;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.response-box {
		width: 100%;
		@include flexCol(flex-start, flex-start, 16px);
		> h4 {
			color: #182230;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
		> p {
			width: 100%;
			border-radius: 12px;
			border: 0.5px solid #dce1ef;
			background: #fff;
			overflow: auto;
			padding: 24px;
			color: #718096;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
	}
}

.preview-mapping-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	padding: 40px;
	border-radius: 12px;
	box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
	padding: 28px;
	@include flexCol(center, center, 20px);
	border: none !important;
	width: 500px;

	.header {
		width: 100%;
		@include flexRow(center, space-between, 16px);
		> div {
			max-width: 85%;
			h3 {
				width: max-content;
				max-width: 100%;
			}
			.column-description {
				margin-top: 5px;
				font-size: 16px;
				color: #718096;
				max-width: 100%;
			}
		}
		.actions {
			@include flexRow(center, flex-end, 16px);

			> span {
				@include flexCol(center, center);
				border-radius: 50%;
				border: 1px solid #d0d5dd;
				font-size: 20px;
				color: #718096;
				width: 40px;
				height: 40px;
				cursor: pointer;
			}
		}
	}
	.sub-heading {
		color: #718096;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 125% */
		letter-spacing: -0.32px;
	}

	.preview-table {
		margin: 15px auto;
		width: 100%;
		table {
			border-radius: 8px;
			border: 0.5px solid #dce1ef;
			background: #fff;
			tr {
				text-align: center;
				width: 100%;
				td {
					border-top: 0.5px solid #dce1ef;
					border-right: 0.5px solid #dce1ef;
					background: #fff;
					padding: 12px;
					color: #000;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					&:last-child {
						border-right: none;
					}
				}
				th {
					border-right: 0.5px solid #dce1ef;
					background: #f8f9fc;
					color: #000;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					padding: 12px 22px;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
}

.edit-mapping-modal,
.preview-mapping-modal {
	.description-container {
		max-width: 300px;
		h3 {
			color: #000;
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px; /* 136.364% */
			width: 100%;
		}
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			width: 100%;
		}
	}
	.action-buttons {
		width: 100%;
		@include flexRow(center, space-between, 16px);
		.cancel-btn {
			padding: 14px 24px;
			border-radius: 10px;
			border: 1px solid #d0d5dd;
			background: #fff !important;
			color: #475467;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		.cta-btn {
			padding: 14px 24px;
			flex: 1;
		}
	}
}

.example-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	padding: 40px;
	border-radius: 12px;
	box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
	padding: 28px;
	@include flexCol(flex-start, center, 16px);
	border: none !important;
	width: 500px;
	h3 {
		color: #000;
		font-family: "DM Sans";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px; /* 136.364% */
	}
	.example-description {
		color: #718096;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
	.editor-container {
		width: 100%;
		height: 300px;
		overflow: auto;
		@include flexCol(flex-start, flex-start, 16px);
		.editor {
			width: 100%;
			border-radius: 12px;
			border: 0.5px solid #dce1ef;
			background: #fff;
			height: max-content;
			.cm-editor {
				min-height: unset !important;
			}
			.mapping-text-editor {
				padding: 24px;
				border-radius: 12px;
				width: 100%;
				border: none;
				font-family: "DM Mono", monospace;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
				color: #000;
				resize: none;
				outline: none;
			}

			.mapping-code-editor {
				width: 100%;
				height: 100%;
				padding: 12px;
				border-radius: 12px;
			}
		}
	}
	.close-example {
		width: 100%;
		border-radius: 10px;
		border: 1px solid #d0d5dd !important;
		background: #fff !important;
		color: #475467;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		padding: 14px 24px;
	}
}
