.cashflow-button {
	width: 100%;
	height: 50px;
	.add-cashflow {
		width: 100%;
		height: inherit;
		@include flexRow(center, center, 0);
		cursor: pointer;
		span {
			color: #25aae5;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px; /* 128.571% */
			letter-spacing: -0.56px;
		}
	}

	.selected-cashflow {
		height: inherit;
		@include flexRow(center, flex-start, 20px);
		padding-inline: 16px;

		.cashflow-details {
			flex: 1;
			.label {
				color: #475467;
				font-family: "DM Mono";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				letter-spacing: -1.2px;
				text-transform: lowercase;
			}
			.cashflow-name {
				@include flexRow(center, flex-start, 0);
				color: #182230;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				span {
					@include flexRow(center, flex-start, 0);
					&::after {
						content: " ";
						display: inline-block;
						width: 1px;
						height: 5px;
						border-radius: 50%;
						background-color: #dfe3f0;
						margin-left: 5px;
						margin-right: 5px;
					}
					&:last-child::after {
						display: none;
					}
				}
			}
		}
	}
}

.user-avatar {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;
	background-color: #7f56d9;
	color: #fff;
	@include flexRow(center, center);
	font-weight: 500;
}

.cashflow-modal {
	@include flexCol(flex-start, flex-start, 16px);
	> h3 {
		color: #000;
		font-family: "DM Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 122.222% */
	}
	.search-box {
		width: 100%;
	}
	.cashflow-list {
		@include flexCol(flex-start, flex-start, 0);
		border-radius: 8px;
		width: 100%;
		height: 400px;
		overflow-y: auto;
		border: 0.5px solid #f1f3f9;
		.cashflow-item {
			width: 100%;
			@include flexRow(center, flex-start, 0);
			border-bottom: 0.5px solid #f1f3f9;
			cursor: pointer;
			&:hover {
				background-color: #fafbf9;
			}
			> span {
				padding: 10px 16px;
				height: 50px;
				@include flexRow(center, flex-start, 10px);
				width: 100px;
				&.run-name {
					flex: 1;
				}
				&.run-type {
					width: 150px;
				}
				color: #000;
				font-family: DM Sans;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				text-transform: capitalize;
				border-right: 0.5px solid #f1f3ff;
			}
			&.header {
				span {
					color: #000;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					border: 0.5px solid #f1f3f9;
				}

				background: #f8f9fc;
			}
		}
	}
	.button-box {
		width: 100%;
		@include flexRow(center, flex-end, 0);
		.cancel-button {
			width: 150px;
		}
	}
}
