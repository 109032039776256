.action-parameters {
	height: 100%;
	width: 23.5vw;
	padding: 12px 24px;
	> h3 {
		color: #475467;
		font-family: "DM Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		letter-spacing: 0.5px;
		text-transform: uppercase;
		margin-bottom: 8px;
	}
	.parameter-selector {
		width: 100%;
		@include flexCol(flex-start, flex-start, 8px);
	}
}

.drop-down-input {
	width: 100%;
	.selected-value-container {
		width: 100%;
		border-radius: 8px;
		border: 1px solid #f1f3f9;
		background: #fff;
		padding: 6px 12px;
		@include flexRow(center, space-between, 0);
		&.disabled-container {
			cursor: not-allowed;
			border: 1px solid #f1f3f9;
			background: #fafbff;
		}
		.text {
			@include flexCol(flex-start, flex-start, 5px);
			> p {
				color: #475467;
				font-family: "DM Mono", monospace;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				letter-spacing: -0.96px;
				text-transform: capitalize;
			}
			> h4 {
				color: #182230;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				text-align: left;
			}
		}
		.icon {
			@include flexRow(center, center, 0);
			svg {
				width: 16px;
				height: 16px;
				color: #718096;
			}
			transition: all 0.1s ease-in-out;
			&.rotate90d {
				transform: rotate(90deg);
			}
			&.rotate0d {
				transform: rotate(0deg);
			}
		}
	}
}

.menu-list {
	width: 100% !important;
	max-width: 330px;
	min-width: 150px;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	padding-top: 0;
	> li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		padding: 12px 16px;
		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}
		.menu-item {
			width: 100%;
			@include flexRow(center, flex-start, 8px);
			> h4 {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.28px;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}
