.lithe-page {
	width: 100%;
	height: 100vh;
	@include flexCol(flex-start, flex-start, 0px);
	position: relative;
	.lithe-step-container {
		width: 100%;
		height: calc(100vh - 154px);
		&.show-with-no-footer {
			height: calc(100vh - 90px);
		}
	}
	.lithe-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		@include flexRow(center, flex-end, 16px);
		padding: 12px 24px;
		border-top: 1px solid #f2f4f7;
		background: #fff;
		.btn-group {
			@include flexRow(center, flex-end, 12px);

			.btn-type-primary {
				min-height: 40px;
				padding: 8px 12px !important;
				color: #fff;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 171.429% */
				&.disabled {
					background-color: #d0d5dd;
					border: 1px solid #d0d5dd;
				}
			}
		}
	}
}

.lithe-menu {
	position: relative;
	.lithe-menu-list {
		&.show {
			display: block;
		}
		&.hide {
			display: none;
		}
		position: absolute;
		left: 0;
		z-index: 2000;
		width: 100% !important;
		min-width: 150px;
		@include flexCol(stretch, flex-start, 0px);
		max-height: 350px;
		overflow-y: auto;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		&.direction-top {
			bottom: 150%;
		}
		&.direction-bottom {
			top: 110%;
		}

		/* Custom scrollbar styles */
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-track {
			background: #eaedf5;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #c1c7d0;
			border-radius: 4px;
			border: 2px solid #f1f3f9;
		}
		&::-webkit-scrollbar-thumb:hover {
			background-color: #a0a7b5;
		}
		.menu-item-container,
		.search-container {
			border-bottom: 1px solid #f8f9fc;
			@include flexRow(center, space-between, 0px);
			padding: 12px 16px;
			width: 100%;
			cursor: pointer;
			&:hover {
				background: #f8f9fc;
			}
			&.selected {
				background: #f8f9fc;
			}
			.menu-item {
				> p {
					color: #475467;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 18px; /* 128.571% */
					letter-spacing: -0.28px;
				}
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		.search-container {
			height: 50px;
			padding: 5px;
			@include flexRow(center, space-between, 0px);
			&:hover {
				background: #fff;
			}
			.search-input {
				width: 100%;
				flex: 1 1 auto;
				height: 40px;
				padding: 0px;
				border: none !important;
				background: #fff !important;
				box-shadow: none !important;
				border-bottom: 2px solid #fff !important;
				color: #000;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
				border-radius: 0 !important;
				@include flexRow(center flex-start, 0px);
				input {
					height: 100%;
					padding-inline: 16px;
					flex: 1 1 auto;
					&::placeholder {
						color: #718096;
					}
				}
				svg {
					margin-right: 10px;
					font-size: 20px;
					color: #718096;
				}
				&:focus-within::before {
					border: none;
					outline: none !important;
					box-shadow: none !important;
					border-bottom: 2px solid var(--primary);
				}
				&.Mui-focused {
					border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}
			}
		}
	}
}
