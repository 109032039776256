.Toastify__toast-theme--light {
	@include flexRow(center, flex-start, 10px);
	padding: 16px;
	border-radius: 12px;
	background: rgba(12, 17, 29, 0.9);
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
	.Toastify__close-button {
		color: #98a2b3;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.Toastify__toast-body {
		color: #fff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px; /* 128.571% */
	}
}
