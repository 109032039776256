.edit-maestro-drawer {
	height: 100%;
	width: 100%;
	background-color: #fff;
	padding: 16px;
	position: relative;
	.edit-maestro-drawer-header {
		> h3 {
			color: #344054;
			font-family: "DM Sans";
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 120% */
		}
		> p {
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}
	.lithe-type-toggler {
		margin-top: 16px;
		width: 100%;
		@include flexRow(center, space-between 16px);
		.lithe-type-question {
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			margin-right: 8px;
		}
		span {
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
			&.active {
				color: #25aae5;
				font-weight: 500;
			}
		}
	}

	.maestro-parameters {
		margin-top: 24px;
		width: 100%;
		border: 1px solid #e6eaef;

		.maestro-row {
			width: 100%;
			height: 80px;
			display: grid;
			grid-template-columns: 1fr 1fr 60px;
			&.vijay-hack {
				grid-template-columns: 1fr 1fr 1fr 60px;
			}
			.maestro-input-box {
				width: 100%;
				overflow: hidden;
				padding: 11px 16px;
				border-right: 1px solid #e6eaef;
				border-bottom: 1px solid #e6eaef;
				&:last-child {
					border-right: none;
				}
				height: inherit;
				.selected-value-container {
					width: 100%;
					border: none;
					background-color: #fff;
					@include flexRow(center, space-between, 0);

					.text {
						@include flexCol(flex-start, flex-start, 5px);
						> p {
							color: #475467;
							font-family: "DM Mono", monospace;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px; /* 133.333% */
							letter-spacing: -0.96px;
							text-transform: lowercase;
						}
						> h4 {
							color: #182230;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							text-align: left;
						}
					}
					.placeholder {
						width: 100%;
						@include flexCol(flex-start, flex-start, 5px);
						> p {
							color: #718096;
							font-family: "DM Mono";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
							letter-spacing: -0.28px;
						}
					}
					.icon {
						@include flexRow(center, center, 0);
						svg {
							width: 16px;
							height: 16px;
							color: #718096;
						}
						transition: all 0.1s ease-in-out;
						&.rotate90d {
							transform: rotate(90deg);
						}
						&.rotate0d {
							transform: rotate(0deg);
						}
					}
					&:hover {
						cursor: pointer;
						background-color: #fff;
					}
				}
				&.lithe-selection {
					// @include flexRow(center, space-between, 0);
					.selected-value-container {
						// width: calc(100% - 30px) !important;
					}
				}
				&.maestro-timing {
					.selected-value-container {
						.text {
							width: 100%;
							.maestro-timing-input-field {
								width: 100%;
								border-radius: 4px;
								border: 1px solid #eaedf5;
								background: #fff;
								box-shadow: none;
								height: 40px;
								padding: 0 8px;
								color: #182230;
								&.Mui-disabled {
									opacity: 0.8;
								}
							}
						}
					}
				}
			}
			.delete {
				width: 60px;
				height: inherit;
				@include flexRow(center, center, 0);
				border-bottom: 1px solid #e6eaef;
				&:hover {
					cursor: pointer;
					background-color: #f8f9fc;
				}
			}
		}

		.add-maestro-btn-container {
			padding: 16px 20px;
			@include flexRow(center, flex-end, 16px);
			.add-maestro-btn {
				@include flexRow(center, center, 5px);
				border-radius: 8px;
				padding: 10px 20px;
				border: 1px solid #25aae5;
				background-color: #fff !important;
				/* Shadows/shadow-xs */
				box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
				color: #25aae5;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 133.333% */
				text-transform: capitalize;
			}
		}
	}

	.drawer-footer {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		@include flexRow(center, space-between);
		border-top: 1px solid #f2f4f7;
		background: #fff;
		padding: 12px 16px;
		.outlined-btn {
			border-radius: 8px;
			border: 1px solid #d0d5dd;
			background: #fff !important;
			display: flex;
			padding: 10px 20px;
			justify-content: center;
			align-items: center;
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			text-transform: capitalize;
		}
		.footer-button-group {
			@include flexRow(center, flex-end, 16px);
			.primary-btn {
				display: flex;
				padding: 10px 20px;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				background: #25aae5;
				color: #fff;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				text-transform: capitalize;
			}
		}
	}
}

.maestro-lithe-list {
	width: 100% !important;
	z-index: 1500 !important;
	max-width: 330px;
	min-width: 150px;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	padding: 0 !important;
	li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		min-height: 75px;
		@include flexRow(stretch, space-between, 0px);
		.menu-item {
			width: calc(100% - 50px);
			height: inherit;
			padding-right: 8px;
			@include flexRow(center, flex-start, 0px);
			.option-title {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.28px;
			}
		}
		.view-lithe {
			margin: auto;
			@include flexRow(center, center, 0);
			width: 50px;
			height: 50px;
			border-radius: 50%;
			svg {
				width: 20px;
				height: 20px;
			}
			&:hover {
				background-color: #fff;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}
