.raw-probability {
	width: 100%;
	height: calc(100vh - 154px);
	padding: 24px 5%;
	@include flexCol(flex-start, flex-start, 36px);
	position: relative;
	overflow: auto;
	.buckets {
		@include flexCol(flex-start, flex-start, 16px);

		.bucket {
			border-radius: var(--radius-md, 8px);
			border: 1px solid #f0f2f8;
			@include flexRow(flex-start, flex-start, 0px);
			height: 50px;
			overflow: hidden;
			.bucket-title {
				@include flexRow(center, center, 0px);
				height: inherit;
				background: #f8f9fc;
				min-width: 200px;
				padding: 0 20px;
				color: #344054;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 14px; /* 87.5% */
			}
			.bucket-values {
				height: inherit;
				@include flexRow(flex-start, flex-start, 0px);
				.bucket-value {
					height: inherit;
					min-width: 150px;
					padding: 0 20px;
					@include flexCol(center, center, 5px);
					border-left: 1px solid #ececec;
					position: relative;
					.active-bucket,
					.confirmed-bucket {
						position: absolute;
						left: 20px;
					}
					.bucket-num {
						color: #718096;
						font-family: "DM Mono";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 12px; /* 100% */
						letter-spacing: -0.24px;
						text-transform: lowercase;
					}
					.bucket-name {
						color: #344054;
						font-family: "DM Mono";
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 14px; /* 87.5% */
					}
				}
			}
		}
	}
	.raw-probability-matrix {
		width: 100%;
		.head {
			margin-bottom: 12px;
			color: #344054;
			font-feature-settings:
				"ss01" on,
				"cv01" on,
				"cv11" on;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
		}
	}
	.raw-probability-actions {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		@include flexRow(center, flex-end, 16px);
		padding: 12px 24px;
		border-top: 1px solid #f2f4f7;
		background: #fff;
		.btn-type-primary {
			min-height: 40px;
			padding: 8px 12px !important;
			color: #fff;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
			&.disabled {
				background-color: #d0d5dd;
				border: 1px solid #d0d5dd;
			}
		}
		.btn-type-tertiary {
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			min-height: 40px;
			padding: 8px 12px !important;
			color: #434f61;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 171.429% */
			&.disabled {
				background-color: #d0d5dd;
				border: 1px solid #d0d5dd;
			}
		}
	}
}

.raw-matrix-box {
	width: 100%;
	.raw-matrix {
		border: 1px solid #f0f2f8;
		width: 100%;
		.raw-matrix-top {
			width: 100%;
			height: 50px;
			@include flexRow(flex-start, flex-start, 0px);
			.raw-matrix-top-left {
				height: inherit;
				border: 1px solid #f0f2f8;
				background: #f9fafc;
				color: #000;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
				padding: 12px 16px;
				width: 200px;
			}
			.raw-matrix-top-right {
				height: inherit;
				@include flexRow(center, center, 0px);
				flex: 1;
				padding: 12px 16px;
				overflow: hidden;
				color: #718096;
				text-overflow: ellipsis;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				border: 1px solid #f0f2f8;
			}
		}
		.raw-matrix-bottom {
			@include flexRow(stretch, flex-start, 0px);
			.raw-matrix-bottom-left {
				border: 1px solid #f0f2f8;
				width: 50px;
				height: 100%;
				min-height: 300px;
				position: relative;
				@include flexRow(center, center, 0px);
				.title {
					// position: absolute;
					// bottom: 0;
					// left: 0;
					transform: rotate(270deg);
					// transform-origin: 0 0;
					color: #000;
					font-family: "DM Mono";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					letter-spacing: -0.28px;
					height: 50px;
					width: max-content;
					@include flexRow(center, center, 0px);
				}
			}
			.raw-matrix-bottom-right {
				flex: 1;
				height: 100%;
				@include flexRow(flex-start, flex-start, 0px);
				.axis-breakpoints {
					width: 150px;
					height: 100%;
					@include flexCol(center, center, 0px);
					list-style: none;
					border: 1px solid #f0f2f8;
					background: #f9fafc;
					li {
						height: 50px;
						width: 100%;
						padding: 12px 16px;
					}
				}
				.matrix {
					flex: 1;
					.matrix-row {
						width: 100%;
						display: grid;
						.matrix-cell {
							width: auto;
							height: 50px;
							border: 1px solid #f0f2f8;
							padding: 12px 16px;
							color: #718096;
							font-family: "DM Sans";
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 20px; /* 142.857% */
						}
					}
				}
			}
		}
	}
}

.raw-probability-input-modal {
	padding: 20px 28px;
	@include flexCol(flex-start, flex-start, 20px);
	h3 {
		color: #000;
		font-family: "DM Sans";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px; /* 136.364% */
	}
	.meta {
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}
	.input-text-area {
		width: 100%;
	}
	.btn-group {
		width: 100%;
		@include flexRow(center, flex-start, 16px);
		.btn-type-primary {
			flex: 1;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			padding: 14px 24px !important;
		}
		.btn-type-tertiary {
			border-radius: var(--radius-md, 8px) !important;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			color: #434f61;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			padding: 14px 24px !important;
		}
	}
}

.probabilities-container {
	.probabilities-table {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		.prob {
			border: 1px solid #f0f2f8;
			@include flexCol(flex-start, flex-start, 3px);

			.bucket-title {
				@include flexRow(center, center, 0px);
				flex: 1;
				padding: 12px 16px;
				overflow: hidden;
				color: #718096;
				text-overflow: ellipsis;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}

			.bucket {
				height: 50px;
				@include flexRow(center, center, 0px);
				text-align: center;
				width: 100%;
				color: #718096;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.28px;
				border-bottom: 1px solid #f0f2f8;
			}
			.prob-val {
				height: 50px;
				text-align: center;
				@include flexRow(center, center, 0px);
				width: 100%;
				color: #000;
				font-feature-settings:
					"ss01" on,
					"cv01" on,
					"cv11" on;
				font-family: "DM Mono";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.84px;
			}
		}
	}
}
