.cashflow-drawer {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 24px 5% 0 5%;
	background-color: #fff;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.head-container {
		width: 100%;
		height: 80px;
		@include flexRow(center, space-between, 0px);
		margin-bottom: 20px;
		.heading {
			color: #000;
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 40px; /* 181.818% */
			@include flexRow(center, flex-start, 0px);
		}
		.btn-type-primary {
			display: flex;
			min-height: 40px;
			padding: 8px 16px !important;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			background: #25aae5;
			color: #fff;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			text-transform: capitalize;
		}
	}
}
