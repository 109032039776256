.navigation {
	height: 60px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-bottom: 1px solid #eaedf5;
	background: #fff;
	@include flexRow(center, space-between);
	padding: 5px 48px;
	.brand {
		@include flexRow(center, flex-start, 50px);
		// h4 {
		//   color: var(--alloan-light-grey) !important;
		// }
		.logo {
			width: auto;
			height: 40px;
			cursor: pointer;
			// margin-right: 10px;
		}
		.nav-links {
			@include flexRow(flex-start, flex-start, 20px);
			list-style: none;
			.link {
				color: #333;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				text-decoration: none;
				padding: 5px;
				width: calc(max-content + 15px);
				position: relative;
				cursor: pointer;
				&.active {
					color: #000 !important;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
				}
			}
		}
	}
	.actions {
		@include flexRow(center, flex-start, 10px);
		.logout {
			@include flexRow(center, center);
			cursor: pointer;
			> svg {
				width: 30px;
				height: 30px;
				margin-right: 5px;
			}
		}
		.tenant-dropdown {
			@include flexRow(center, center);
			cursor: pointer;
			border-radius: 50px;
			background: #f8f9fc;
			padding: 10px 12px;
			height: 40px;
			min-width: 150px;
			> button {
				color: #000;
				text-align: center;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				letter-spacing: -0.42px;
				text-transform: capitalize;
			}
		}
		.avatar {
			@include flexRow(center, center);
			padding-right: 10px;
			border-right: 2px solid var(--alloan-light-grey);
			// cursor: pointer;
			> svg {
				width: 30px;
				height: 30px;
				margin-right: 5px;
			}
			p {
				color: var(--alloan-white);
				font-size: 16px;
				font-weight: 500;
				text-transform: capitalize;
			}
		}
	}
}

.user-meta {
	cursor: unset !important;
	opacity: 1 !important;
	@include flexRow(flex-start, flex-start, 10px);
	.content {
		@include flexCol(flex-start, flex-start, 0px);

		> h6 {
			color: #000;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
		> p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}
}

.tenant-option {
	color: #000 !important;
	font-family: "DM Sans" !important;
	font-size: 14px !important;
	border-bottom: 0.5px solid #eaedf5 !important;
	background: #fff;
	text-transform: capitalize;
	&:last-child {
		border-bottom: none !important;
	}
	&.Mui-selected {
		background-color: transparent !important;
		color: var(--Primary, #25aae5) !important;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}
