.upload-tape {
	width: 450px;
	margin: auto;
	@include flexCol(center, center, 24px);
	padding: 20px;
	border-radius: 8px;
	.file-uploader {
		&:focus-within {
			outline: none;
			border: none;
		}
		width: 100%;
		@include flexCol(flex-start, flex-start, 24px);
		.file-selected {
			width: 100%;
			border-radius: 12px;
			// border: 1px solid #e7ebef;
			background: #fff;
			// padding: 16px;
			.file-details {
				@include flexRow(center, space-between, 16px);
				.text {
					flex: 1;
					@include flexCol(flex-start, flex-start, 4px);
					> p {
						max-width: 300px;
						white-space: wrap;
						word-wrap: break-word;
						color: #000;
						text-overflow: ellipsis;
						font-family: "DM Sans";
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 112.5% */
					}
					> span {
						color: rgba(0, 0, 0, 0.4);
						font-family: "DM Sans";
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
				> svg {
					width: 40px;
					height: auto;
					cursor: pointer;
					color: var(--primary);
					&:last-child {
						color: rgba(0, 0, 0, 0.4);
						width: 30px;
					}
				}
			}
			.progress {
				margin-top: 16px;
				color: #f5a622;
			}
		}
		.tape-meta {
			width: 100%;
			@include flexCol(flex-start, flex-start, 24px);
			label {
				color: #718096;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			.input-container {
				border-radius: 8px;
				border: 1px solid rgba(0, 0, 0, 0.25);
				background: #fff;
			}
			.asset-class-select {
				width: 100%;
				@include flexCol(flex-start, flex-start, 10px);
				.asset-class-selector {
					padding: 10px;
					box-shadow: none;
					border-radius: 8px;
					border: 1px solid rgba(0, 0, 0, 0.35);
					background: #fff;
					width: 100%;
					> button {
						width: 100%;
					}
				}
			}
		}
		.drag-zone {
			aspect-ratio: 1/1;
			width: 100%;
			@include flexCol(center, center);
			border-radius: 8px;
			border: 1px dashed rgba(56, 78, 183, 0.3);
			cursor: pointer;
			.icon {
				font-size: 56px;
				color: var(--primary);
			}
			.text {
				> p {
					text-align: center;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 150% */
					span {
						color: #25aae5;
					}
					&.format {
						color: #718096;
						text-align: center;
						font-family: "DM Sans";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}
	}
	.upload-btn {
		width: 100%;
		padding: 15px 14px;
		color: #fff;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 18px; /* 128.571% */
		text-transform: capitalize;
		&.disabled {
			opacity: 0.5;
			background-color: var(--primary) !important;
			color: #fff !important;
			cursor: not-allowed;
		}
	}
}

.upload-tape-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	padding: 40px;
	border-radius: 12px;
	box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05);
	.close-icon {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		color: #718096;
		font-size: 24px;
	}
	> h3 {
		padding: 0 20px;
		color: #000;
		font-family: "DM Sans";
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 40px; /* 181.818% */
	}
	.sub-heading {
		padding: 0 20px;
		color: #718096;
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 125% */
		letter-spacing: -0.32px;
	}
	.upload-tape {
		.file-uploader {
			background-color: #f6fbfe;
			&.no-bg {
				background-color: transparent;
			}
		}
	}
}
