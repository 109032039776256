.analysis-main-screen {
	width: 100%;
	@include flexCol(flex-start, flex-start, 0);

	.main-screen-top {
		width: 100%;
		@include flexRow(flex-start, flex-start, 0);
		border-bottom: 1px solid #f1f3f9;
		.output-graphs {
			flex: 1 1 calc(100% - 370px);
			min-height: 300px;
			// display: grid;
			// grid-template-columns: 1fr 1fr 1fr;
			@include flexRow(flex-start, flex-start, 0);
			.graph-container {
				min-height: 300px;
				height: 100%;
				width: calc(100% / 3);
				position: relative;
				padding: 12px;
				border-right: 1px solid #f1f3f9;
				&:last-child {
					border-right: none;
				}
				.graph-enlarge-icon {
					position: absolute;
					top: 10px;
					right: 10px;
					cursor: pointer;
					width: 16px;
					height: 16px;
					> svg {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
	}
	.main-screen-bottom {
		width: 100%;
		height: 100%;
		@include flexRow(flex-start, flex-start, 0);
		.actions-screen {
			flex: 1 1 calc(100% - 370px);
			display: grid;
			grid-template-columns: 2fr 1fr;
		}
		.main-screen-bottom-loader {
			width: 100%;
			height: 100%;
			gap: 20px;
			.loader__text {
				> h3 {
					color: #101828;
					font-family: "DM Sans";
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
				> p {
					color: var(--black-40, rgba(28, 28, 28, 0.4));
					text-align: right;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
				}
			}
		}
	}
}
