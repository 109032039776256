.sidebar {
	background-color: var(--alloan-white);
	// box-shadow: rgba(50, 50, 93, 0.25) 5px 2px 5px -5px, rgba(0, 0, 0, 0.3) 2px 1px 3px -1px;
	box-shadow:
		rgba(0, 0, 0, 0.12) 0px 1px 3px,
		rgba(0, 0, 0, 0.24) 0px 1px 2px;
	padding: 20px;
	&.sidebar-open {
		min-width: 250px;
		width: 250px;
		.sidebar-content {
			display: block;
		}
	}
}
