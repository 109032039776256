.risk-factor-filter {
	width: 100%;
	height: 100%;
	@include flexRow(flex-start, flex-start, 0px);

	.data-table {
		width: calc(100% - 360px);
		height: 100%;
		@include flexRow(flex-start, flex-start, 0px);
		flex-wrap: wrap;

		&.data-table-empty {
			grid-template-columns: 1fr;
			.click-to-get-results {
				width: 100%;
				height: 100%;
				@include flexCol(center, center, 0);
				h4 {
					color: #101828;
					font-family: "DM Sans";
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}

		.graph-container {
			width: calc(100% / 3);
			height: 50%;
			border-right: 1px solid #f1f3f9;
			border-bottom: 1px solid #f1f3f9;
			background: #fff;
			padding: 12px 8px;
			.lithe-graphs {
				width: 100%;
				height: 100%;
				.graph-div {
					@include flexCol(center, space-between, 0);
					width: 100%;
					height: 100%;
					.graph-label {
						font-size: 14px !important;
					}
				}
			}
		}
	}
}

.data-table-loading {
	display: flex !important;
	@include flexRow(center, center, 0px);
	.graph-loader {
		width: 100%;
		height: 100%;
		gap: 20px;
		.loader__text {
			> h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			> p {
				color: var(--black-40, rgba(28, 28, 28, 0.4));
				text-align: right;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}
	}
}

.input-side-bar {
	width: 360px;

	h3 {
		color: #000;
		font-family: "DM Sans";
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 122.222% */
	}

	.run-to-gen {
		color: #718096;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
	.summary-list {
		list-style: none;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		border: 0.5px solid #e2e7f3;
		li {
			width: 100%;
			border-right: 0.5px solid #e2e7f3;
			border-bottom: 0.5px solid #e2e7f3;
			padding: 6px 12px;

			.label {
				color: #475467;
				font-family: "DM Sans";
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 100% */
			}

			.value {
				color: #182230;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
			}

			&:nth-child(odd) {
				background: #f8f9fc;
			}
			&:nth-child(3n) {
				border-right: none;
			}
			&.no-bottom-border {
				border-bottom: none;
			}
		}

		&:last-child {
			li {
				border-right: none;
			}
		}
	}

	.range-container,
	.option-container,
	.date-container {
		width: 100%;
		padding: 12px;
		border: 1px solid #f1f3f9;
		border-radius: 8px;
		@include flexCol(flex-start, flex-start, 8px);
		position: relative;
		.delete-risk-factor {
			scale: 0.75;
			position: absolute;
			top: 12px;
			right: 12px;
			cursor: pointer;
		}
		label {
			color: #718096;
			font-family: "DM Mono";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px; /* 100% */
			letter-spacing: -0.24px;
			text-transform: lowercase;
		}
		.option-dropdown {
			margin-top: 12px;
			.placeholder {
				color: #718096;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 114.286% */
			}
			.selected-value-container {
				border: none !important;
			}
		}
		.date-group {
			.date-picker {
				width: 100%;
				box-shadow: none;
				border-radius: 8px;
				border: 1px solid #eaedf5;
				background: #fff;
				.MuiInputBase-root {
					width: 100%;
					.MuiInputBase-input {
						height: 40px;
						padding: 0 0 0 12px;
					}
				}
				.MuiOutlinedInput-notchedOutline {
					display: none;
				}
				.MuiInputAdornment-root {
					margin-left: 0;
					scale: 0.75;
					margin-right: -8px;
				}
			}
		}
	}

	.highlight-error {
		border: 1px solid #ff4d4f !important;
	}

	.btn-type-secondary {
		width: 100%;
		border-radius: 8px !important;
		border: 1px solid #25aae5 !important;
		background: #fff !important;
		color: #25aae5 !important;
		font-family: "DM Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 114.286% */
		padding: 12px 16px;
	}
}

.risk-factor-dropdown {
	.selected-value-container {
		min-height: 50px;
		cursor: pointer;
	}
}
