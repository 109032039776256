.analysis-page {
	@include flexCol("flex-start", "flex-start", 0);
	height: 100vh;
	.analysis-layout {
		@include flexRow("flex-start", "flex-start", 0);
		height: calc(100vh - 70px);
	}
	.analysis-page-loader {
		@include flexCol(center, center, 30px);
		height: 100%;
		.loader__text {
			@include flexCol(center, center, 10px);
			> h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			> p {
				color: var(--black-40, rgba(28, 28, 28, 0.4));
				text-align: right;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}
	}
}
