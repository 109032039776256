.main-layout {
	@include flexCol("flex-start", "flex-start", 0px);
	position: relative;
	.container {
		@include flexRow("flex-start", "flex-start", 0);
		min-height: 100vh;
		width: 100%;
		.page-layout {
			flex: 1 1 auto;
		}
	}
}
