.simulation-drawer {
	height: 100%;
	width: 100%;
	background-color: #fff;
	padding: 16px;
	.dropdown-container {
		width: 100%;
		.dropdown-selector {
			border: none !important;
			@include flexRow(center, space-between, 16px);
		}
	}
}

.simulation {
	height: 100%;
	@include flexCol(flex-start, space-between, 16px);
	.graph-loader {
		width: 100%;
		height: 100%;
		gap: 20px;
		.loader__text {
			> h3 {
				color: #101828;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}
			> p {
				color: var(--black-40, rgba(28, 28, 28, 0.4));
				text-align: right;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}
	}
	.graph {
		width: 100%;
		min-height: 300px;
	}
	.questions {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		border: 1px solid #e6eaef;
		.parameter-item {
			@include flexRow(center, space-between, 16px);
			padding: 12px 24px;
			border-bottom: 1px solid #e6eaef;
			&.even {
				border-right: 1px solid #e6eaef;
			}
			&:last-child,
			&:nth-last-child(2) {
				border-bottom: none;
			}
			.content {
				@include flexCol(flex-start, flex-start, 2px);
				label {
					color: #475467;
					font-family: "DM Mono", monospace;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.96px;
					text-transform: lowercase;
				}
				> p {
					color: #182230;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
			.edit-icon {
				cursor: pointer;
				svg {
					width: 20px;
					height: 20px;
				}
			}
			&.highlight-change {
				label {
					color: rgb(233, 53, 53, 0.8);
				}
				p {
					color: rgb(233, 53, 53, 0.8);
				}
			}
		}
	}
	.simulation-footer {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		@include flexRow(center, space-between);
		border-top: 1px solid #f2f4f7;
		background: #fff;
		padding: 12px 16px;
		.btn-type-secondary {
			border-radius: 8px;
			border: 1px solid #d0d5dd;
			background: #fff !important;
			display: flex;
			min-height: 40px;
			padding: 8px 16px !important;
			justify-content: center;
			align-items: center;
			color: #475467;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			text-transform: capitalize;
		}
		.button-group {
			@include flexRow(center, flex-end, 16px);
			.btn-type-primary {
				display: flex;
				min-height: 40px;
				padding: 8px 16px !important;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				background: #25aae5;
				color: #fff;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
				text-transform: capitalize;
			}
		}
	}
}

.simulation-menu {
	width: 300px;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	z-index: 9999 !important;
	top: 4px !important;
	left: 16px !important;
	> li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		padding: 12px 16px;
		.menu-item {
			width: 100%;
			h4 {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.28px;
			}
		}
		&:hover {
			background-color: transparent !important;
			cursor: pointer;
		}
	}
}
