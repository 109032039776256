.login-page {
	@include flexCol(center, center);
	background-image: url("./../../assets/images/loginBg.png");
	width: 100%;
	min-height: 100vh;
	.login-card {
		max-width: 450px;
		border-radius: 8px;
		background: #fff;
		// box-shadow: 0px 0px 12px 0px rgba(16, 24, 40, 0.04);
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		padding: 40px;
		@include flexCol(center, center, 15px);
		.logo {
			margin: auto;
		}
		> h2 {
			color: #23262f;
			text-align: center;
			font-family: "DM Sans";
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 28px; /* 116.667% */
			> span {
				color: var(--primary);
			}
		}
		> h4 {
			color: #475467;
			text-align: center;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px; /* 137.5% */
		}
		.login-form {
			width: 100%;
			.input-label-container {
				> label {
					color: #344054;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 142.857% */
				}
			}
			@include flexCol($gap: 20px);
			.password-field {
				.end-decorator {
					cursor: pointer;
					> svg {
						width: 25px;
						height: 25px;
						fill: var(--dark-2);
					}
				}
			}
			.primary-btn {
				width: 100%;
				padding: 12px;
			}
			.logout-btn {
				width: 100%;
				padding: 12px 16px;
				border: none;
				border-radius: 8px;
				background-color: #fff !important;
				color: var(--primary);
				border: 1px solid var(--primary);
				margin-top: -10px;
			}
		}
		.support {
			margin-top: 30px;
			width: 100%;
			text-align: left;
			@extend .p-sm;
			font-size: 12px;
			> a {
				color: var(--primary);
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.create-password {
	height: 100vh;
	min-height: auto;
}
