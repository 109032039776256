.assumption-panel {
	@include flexCol(flex-start, flex-start, 32px);
	.assumption-selector {
		width: 100%;
		position: relative;
		.remove-filters {
			width: 100%;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			background: #fff;
			@include flexRow(center, space-between, 10px);
			padding: 12px;
			cursor: pointer;
			flex: 1;
			.text {
				@include flexCol(flex-start, flex-start, 0px);
				h4 {
					color: #182230;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				p {
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.24px;
				}
			}
		}

		.assumption-name {
			width: 100%;
			border-radius: 8px;
			border: 1px solid #f1f3f9;
			background: #fff;
			@include flexRow(center, space-between, 10px);
			padding: 12px;
			cursor: pointer;
			.text {
				flex: 1;
				@include flexCol(flex-start, flex-start, 0px);
				> h4 {
					color: #182230;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
				> p {
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 16px; /* 133.333% */
					letter-spacing: -0.24px;
				}
			}
			.icon {
				width: 16px;
				height: 16px;
				transition: all 0.1s ease-in-out;
				&.rotate90d {
					transform: rotate(90deg);
				}
				&.rotate0d {
					transform: rotate(0deg);
				}
			}
		}
	}
	.assumption-content,
	.filter-option {
		width: 100%;
		@include flexCol(flex-start, flex-start, 16px);
		.panel-heading {
			color: #475467;
			font-family: "DM Sans";
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; /* 133.333% */
			letter-spacing: 0.5px;
			text-transform: uppercase;
		}
		.toggler-2d-3d {
			width: 100%;
			background: #fff;
			height: 40px;
			@include flexRow(center, space-between, 12px);
			cursor: pointer;
			> span {
				@include flexRow(center, center, 12px);
				padding: 10px 12px;
				flex: 1 1 auto;
				color: #718096;
				text-align: center;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 133.333% */
				opacity: 0.8;
				text-transform: uppercase;
				border-radius: 8px;
				border: 1px solid #d0d5dd;
			}
			.active {
				color: #fff;
				text-align: center;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 16px; /* 133.333% */
				border-radius: 8px;
				background: #25aae5;
				opacity: 1;
			}
		}
		.assumptions-list {
			width: 100%;
			list-style: none;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 12px;
			li {
				border-radius: 8px;
				border: 1px solid #f1f3f9;
				background: #fff;
				padding: 12px 14px;
				@include flexCol(flex-start, flex-start, 2px);
				label {
					color: #475467;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 12px; /* 100% */
				}
				p {
					color: #000;
					font-family: "DM Sans";
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}
			}
		}
		.axis-controllers {
			width: 100%;
			@include flexCol(flex-start, flex-start, 12px);

			.axis {
				width: 100%;
				@include flexCol(flex-start, flex-start, 0px);
				border-radius: 8px;
				border: 1px solid #f1f3f9;
				background: #fff;
				padding: 10px 12px;
				color: #475467;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				.MuiSelect-root {
					padding-left: 0 !important;
					background-color: transparent !important;
					border: none !important;
					width: 100%;
					box-shadow: none !important;
				}
				label {
					color: #718096;
					font-family: "DM Sans";
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 12px; /* 100% */
					text-transform: lowercase;
				}
			}
		}
	}
	.filter-option {
		width: 100%;
		.filter-box {
			width: 100%;
			border-radius: 8px;
			border: 1px solid #f0f2f8;
			background: #fff;
			@include flexRow(center, flex-start, 12px);
			cursor: pointer;
			padding: 10px 14px;
			position: relative;
			> p {
				@include flexRow(center, space-between, 8px);
				flex: 1 0 calc(100% - 40px);
				color: #000;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px; /* 142.857% */
				svg {
					color: #718096;
				}
				.filter-text {
					width: 100%;
					@include flexRow(center, space-between, 0px);
				}
				.filter-indicator {
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background: #f24e1e;
					@include flexRow(center, center, 0px);
					color: #fff;
					font-family: "DM Sans";
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					line-height: 8px; /* 100% */
					letter-spacing: -0.16px;
				}
			}
		}
	}
}

.assumption-menu {
	width: 300px;
	// transform: translate3d(24px, 160px, 0px) !important;
	@include flexRow(center, space-between, 0px);
	max-height: 350px;
	overflow-y: auto;
	> li {
		border-bottom: 1px solid #f8f9fc;
		background: #fff;
		padding: 12px 16px;
		.menu-item {
			width: 100%;
			h4 {
				color: #475467;
				font-family: "DM Sans";
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 128.571% */
				letter-spacing: -0.28px;
			}
			p {
				color: #98a2b3;
				font-family: "DM Sans";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				letter-spacing: -0.24px;
			}
		}
		&:hover {
			background-color: transparent !important;
			cursor: pointer;
		}
	}
}
