.save-simulation-modal {
	border-radius: 12px;
	padding: 20px 28px;
	@include flexCol(flex-start, flex-start, 20px);
	.header {
		width: 100%;
		@include flexCol(flex-start, flex-start, 6px);
		h3 {
			color: #000;
			font-family: "DM Sans";
			font-size: 22px;
			font-style: normal;
			font-weight: 500;
			line-height: 30px; /* 136.364% */
		}
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 125% */
			letter-spacing: -0.32px;
		}
	}
	.input-container {
		width: 100%;
		@include flexCol(flex-start, flex-start, 8px);
		p {
			color: #718096;
			font-family: "DM Sans";
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
		.simulation-input {
			height: 48px;
			width: 100%;
			border-radius: 8px;
			border: 1px solid #eaedf5;
			background: #fff !important;
			box-shadow: none !important;
			color: #000;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
			input {
				&::placeholder {
					color: #718096;
				}
			}
		}
	}
	.bottom-btn {
		margin-top: 10px;
		width: 100%;
		@include flexRow(flex-start, space-between, 16px);
		.cancel-btn {
			border-radius: 10px;
			border: 1px solid #d0d5dd !important;
			background: #fff !important;
			padding: 14px 24px;
			color: #475467;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
		}
		.save-btn {
			flex: 1 1 auto;
			padding: 14px;
			color: #fff;
			font-family: "DM Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 125% */
			text-transform: capitalize;
			border-radius: 10px;
			background: #25aae5;
		}
	}
}
